import { useNavigate } from "react-router-dom";
import { PlayerVoteCard } from "./PlayerVoteCard";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectMatchPlayerVote } from "../../../store/selectors";
import { selectPlayer } from "../../../store/slices/matchPlayerVote/reducer";
import { voteForPlayer } from "../../../api/transport/contests/matchVoteTransport";
import { TicketCheckResult } from "../../../store/slices/matchPlayerVote/types";
import { TicketForm } from "./TicketForm";

interface PlayerSelectProps {
    onVote?: (playerId: string) => void;
    children?: React.ReactNode;
}

interface Player {
    id: string;
    name: string;
    position: string;
}

export const PlayerSelect: React.FC<PlayerSelectProps> = ({ children, onVote }) => {
    const navigate = useNavigate();

    const [players, setPlayers] = useState<Player[]>([]);
    const [loading, setLoading] = useState(true);
    const [verification, setVerification] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState('');

    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (matchPlayerVote.candidates.length > 0) {
            setPlayers(matchPlayerVote.candidates.map((candidate, index) => ({
                id: index.toString(),
                name: candidate,
                position: '',
            })));
            setLoading(false);
        }

        if (matchPlayerVote.selectedPlayer) {
            //console.log(matchPlayerVote.selectedPlayer);
            //navigate("/vote", { replace: true });
            onVote!(matchPlayerVote.selectedPlayer);
        }
    }, [matchPlayerVote.candidates, matchPlayerVote.selectedPlayer, navigate, onVote]);

    const onVoteHandler = (playerId: string) => {
        dispatch(selectPlayer(playerId));
        if (matchPlayerVote.selectedTicket) {
            voteForPlayer(
                playerId,
                matchPlayerVote.selectedTicket,
                localStorage.getItem("phoneNumber") || '',
                matchPlayerVote.uuid
            );
        } else if (matchPlayerVote.checkedIn) {
            voteForPlayer(
                playerId,
                matchPlayerVote.checkedIn,
                localStorage.getItem("phoneNumber") || '',
                matchPlayerVote.uuid
            );
        }

        onVote!(playerId);
        //localStorage.setItem("selectedPlayer", playerId);
        //navigate("/vote", { replace: true });
    }

    const ticketSelected = matchPlayerVote.checkedIn || (!!matchPlayerVote.selectedTicket && matchPlayerVote.ticketCheckResult === TicketCheckResult.OK);

    return (
        <>
            {verification && <TicketForm
                onSuccess={() => {
                    if (selectedPlayer) {
                        onVoteHandler(selectedPlayer);
                    }
                    setVerification(false);
                }}
            />}
            {!verification && <>
                <h1 className="text-lg text-center">Номинанты на звание</h1>
                <h1 className="text-xl text-center font-bold">Лучший игрок матча<br />«{matchPlayerVote.matchName}»</h1>
                <div className="italic font-light text-center">
                    Чтобы проголосовать нажми на игрока
                </div>
                {players.map((player) => (
                    <PlayerVoteCard
                        key={player.id}
                        name={player.name}
                        position={player.position}
                        id={player.id}
                        onVote={(playerId) => {
                            if (!ticketSelected || !localStorage.getItem("phoneNumber")) {
                                setVerification(true);
                                setSelectedPlayer(playerId);
                                return;
                            }
                            onVoteHandler(playerId)
                        }}
                    />
                ))}
                {children}
                {/*<Button title="Назад" type="link" onClick={() => navigate("/vote")} />*/}
            </>}
        </>
    );
}