import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useUserProfile } from "../../hooks";
import { InternalPage } from "../../components/layout/InternalPage";
import { useAppDispatch, useAppSelector } from "../../store";
import { logout } from "../../store/slices/auth/reducer";
import { getInitials } from "../../utils";
import { PageContainer } from "../../components/layout";
import { Toast, ToastToggle } from "flowbite-react";
import { Link } from "react-router-dom";
import { Button } from "../top-index/components/Button";
import { selectAuth } from "../../store/selectors";

export const ProfilePage = () => {
    const navigate = useNavigate();
    const profile = useUserProfile();
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [showEmailConfirm, setShowEmailConfirm] = useState(false);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const backUrl = searchParams.get('backUrl');

    const onLogout = () => {
        dispatch(logout({}));
        navigate('/auth/login');
    }

    useEffect(() => {
        //console.log("profile", profile);
        if (!auth?.loading && !profile) {
            navigate('/auth/login' + search);
        }
    }, [auth?.loading, navigate, profile, search]);

    return (
        <PageContainer>
            <div className="w-full mx-auto px-4">
                <div className="w-full flex flex-col items-center p-4">
                    <div className="rounded-full w-28 h-28 bg-gray-500 flex items-center text-5xl font-bold text-center justify-center text-white">
                        {getInitials(profile?.name || '')}
                    </div>
                    <div className="p-4 w-full text-center">
                        <h1 className="text-2xl font-bold">{profile?.name}</h1>
                        <h2 className="text-xl font-base text-gray-600">({profile?.email})</h2>
                        {!profile?.confirmed && <div className="flex flex-col">
                            <span className="text-red-600 cursor-pointer" onClick={() => setShowEmailConfirm(true)}>не подтверждён</span>
                        </div>}
                    </div>
                    {showEmailConfirm && <div className="fixed top-0 pt-4">
                        <Toast>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row justify-between items-start">
                                    <div className="text-sm font-normal">
                                        На указанный в профиле e-mail мы выслали письмо для подтверждения регистрации.<br />
                                        Подтверди e-mail и используй приложение на полную мощность
                                    </div>
                                    <div className="ml-auto flex items-center space-x-2">
                                        <ToastToggle onDismiss={() => setShowEmailConfirm(false)} />
                                    </div>
                                </div>
                                {/*<div className="text-center">
                                    <div
                                        onClick={() => setShowEmailConfirm(false)}
                                        className="transition-all font-bold uppercase italic p-2 cursor-pointer active:scale-95 bg-slate-950 text-white rounded-full border-2 border-solid border-white shadow-sm"
                                    >
                                        Запросить повторно
                                    </div>
                                </div>*/}
                            </div>
                        </Toast>
                    </div>}
                </div>
                <div className="flex flex-row gap-3">
                    <div className="flex border border-gray-200 bg-white shadow-md flex-col w-1/2 max-w-48 min-h-32 text-slate-950 rounded-3xl">
                        <div className="w-full h-full px-6 py-4 flex flex-col justify-between">
                            <h2 className="text-xl">Бонусы</h2>
                            <span className="self-end text-5xl font-bold">{profile?.bonuses}</span>
                        </div>
                    </div>
                    <Link to="/profile/tickets" className="flex border border-gray-200 bg-white shadow-md flex-col w-1/2 max-w-48 min-h-32 text-slate-950 rounded-3xl">
                        <div className="w-full h-full px-6 py-4 flex flex-col justify-between">
                            <h2 className="text-xl">Мои билеты</h2>
                            {/*<span className="self-end font-bold">временно недоступно</span>*/}
                        </div>
                    </Link>
                </div>
                {/*<div className="pt-8">
                    <Button title="Выйти" onClick={onLogout} className="w-full" />
                </div>*/}
            </div>
        </PageContainer>
    );
}