export interface AskCoachSuccessPayload extends AskCoachConfig {}

export interface LoadAskCoachResponse extends AskCoachConfig {}

export interface SendAskCoachRequestPayload {
    text: string;
}

export interface SendAskCoachRequestReponse extends AskCoachConfig {}

export enum AskCoachStatus {
    ACTIVE = 0,
    CLOSED = 1,
}

export interface AskCoachConfig {
    status: AskCoachStatus;
    hasRequest?: boolean;
}