import { useState, useEffect } from 'react';
import { openDB, DBSchema } from 'idb';

interface MyDB extends DBSchema {
    files: {
        key: string;
        value: StoredFile;
    };
}

export interface StoredFile {
    name: string;
    type: string;
    size: number;
    content: ArrayBuffer;
    category: string;
    tags: string[];
    uploadDate: string;
    uploadedBy: string; // если есть юзер
}

export const useFileStorage = () => {
    const [fileList, setFileList] = useState<StoredFile[]>([]);

    const dbPromise = openDB<MyDB>('file-storage-db', 1, {
        upgrade(db) {
            db.createObjectStore('files');
        },
    });

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles = async () => {
        const db = await dbPromise;
        const keys = await db.getAllKeys('files');
        const files = await Promise.all(
            keys.map((key) => db.get('files', key))
        );

        setFileList(files.filter(Boolean) as StoredFile[]);
    };

    const MAX_FILE_SIZE_MB = 10;

    const saveFile = async (
        file: File,
        category: string,
        tags: string[],
        uploadedBy: string = 'Anonymous'
    ) => {
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            alert(`Размер файла превышает ${MAX_FILE_SIZE_MB}MB`);
            return;
        }

        const content = await file.arrayBuffer();

        const storedFile: StoredFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            content,
            category,
            tags,
            uploadDate: new Date().toISOString(),
            uploadedBy,
        };

        const db = await dbPromise;
        await db.put('files', storedFile, file.name);

        await loadFiles();
    };

    const deleteFile = async (fileName: string) => {
        const db = await dbPromise;
        await db.delete('files', fileName);
        await loadFiles();
    };

    const getFile = async (fileName: string): Promise<StoredFile | null | undefined> => {
        const db = await dbPromise;
        return db.get('files', fileName);
    };

    return {
        fileList,
        saveFile,
        deleteFile,
        getFile,
    };
};
