import { RootState } from "..";

export const selectMatchPlayerVote = (state: RootState) => state.matchPlayerVote;

export const selectAuth = (state: RootState) => state.auth;

export const selectUserProfile = (state: RootState) => state.auth.userProfile;

export const selectTopIndex = (state: RootState) => state.topIndex;

export const selectConfig = (state: RootState) => state.config;

export const selectAppConfig = (state: RootState) => state.config?.app;

export const selectEventsConfig = (state: RootState) => state.config?.app?.eventsConfig;

export const selectAskCoach = (state: RootState) => state.askCoach;