import { FC, useRef, useState } from 'react';
import logo from '../logo.svg';

interface SplashScreenProps {
    fade?: boolean;
}

export const SplashScreen: FC<SplashScreenProps> = ({ fade }) => {
    const [tapCount, setTapCount] = useState(0);
    const codeRef = useRef<HTMLInputElement>(null);
    const animation = `transition-all duration-200 ${fade ? "opacity-0" : "opacity-100"} `;
    console.log('tapCount', tapCount);
    const onCodeSubmit = () => {
        if (codeRef.current?.value) {
            localStorage.setItem('access_token', codeRef.current.value);
            window.location.reload();
        }
    }
    return (
        <>
            {tapCount >= 10 && <>
                <div className='fixed top-0 left-0 right-0 bottom-0 bg-sky-950/75 flex justify-center items-center z-50 p-12'>
                    <div className="flex flex-col items-center justify-center bg-white min-h-100 w-full h-72 rounded-md">
                        <label className='text-sky-950 text-lg mt-4'>Код доступа</label>
                        <input ref={codeRef} type="text" placeholder='Введи код' className="w-64 h-12 border border-gray-300 rounded-md mt-4 p-2 text-center" />
                        <button className="w-64 h-12 bg-sky-950 text-white rounded-md mt-4" onClick={onCodeSubmit}>Сохранить</button>
                    </div>
                </div>
            </>}
            <div className={animation + "fixed bottom-0 left-0 right-0 top-0 bg-sky-950 flex justify-center items-center z-50"}>
                <div className="flex flex-col items-center">
                    <img src={logo} alt="ФК Родина" className="w-64 h-64" onClick={() => setTapCount(tapCount + 1)} />
                    <div className='text-3xl italic text-white font-bold mt-16'>#ТопиЗаРодину</div>
                </div>
            </div>
        </>
    );
}
