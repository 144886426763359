import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"

interface LinkButtonProps {
    url: string;
    children: ReactNode;
    disabled?: boolean;
}

export const LinkButton: FC<LinkButtonProps> = ({
    url,
    children,
    disabled,
}) => {
    let classNames = "w-full rounded-full bg-white text-slate-950 text-xl text-center p-4 px-8 uppercase font-bold !hover:bg-white/75 !focus:bg-white/75 focus:ring-0";
    if (disabled) {
        classNames += " cursor-not-allowed opacity-50 pointer-events-none";
    }
    return (
        <Link
            to={url}
            className={classNames}
        >
            {children}
        </Link>
    )
}

interface ButtonProps {
    onClick: () => void;
    children: ReactNode;
    disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
    onClick,
    children,
    disabled,
}) => {
    let classNames = "transition-all active:scale-95 w-full max-w-80 rounded-full bg-white text-slate-950 text-xl text-center p-4 px-8 uppercase font-bold !hover:bg-white/75 !focus:bg-white/75 focus:ring-0 cursor-pointer";
    if (disabled) {
        classNames += " cursor-not-allowed opacity-50 pointer-events-none";
    }
    return (
        <div
            onClick={onClick}
            className={classNames}
        >
            {children}
        </div>
    )
}