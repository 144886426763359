import { FC, ReactNode } from 'react';

export * from './Modal';
export * from './PageHeader';

interface PageContainerProps {
    children?: ReactNode;
    noPadding?: boolean;
}

export const PageContainer: FC<PageContainerProps> = ({
    children,
    noPadding,
}) => {
    let classNames = "pt-8 pb-24 w-full h-full sm:max-w-96 mx-auto flex flex-col justify-start items-center gap-4 text-sky-950 dark:text-white";

    if (!noPadding) {
        classNames += " px-3";
    }

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}