import { FC } from "react";
import { Popup } from "../../components/Popup";
import { RoundButton as Button } from "../../components/Button";

interface RulesPopupProps {
    show?: boolean;
    onClose?: () => void;
}

export const RulesPopup: FC<RulesPopupProps> = ({
    show,
    onClose,
}) => {
    if (!show) {
        return null;
    }

    return (
        <Popup
            onClose={() => onClose!()}
            title="Соблюдай эти простые правила, когда пишешь вопрос"
        >
            <div className="text-lg text-white flex flex-col gap-2 p-2">
                <div>
                    <b>1. Будь вежливым</b>
                    <ul className="pl-8 pt-4 list-disc">
                        <li>
                            Вопросы должны быть корректными и уважительными.
                        </li>
                        <li>
                            Запрещены оскорбления, нецензурная лексика, провокации и дискриминационные высказывания.
                        </li>
                    </ul>
                </div>
                <div>
                    <b>2. Формат вопросов</b>
                    <ul className="pl-8 pt-4 list-disc">
                        <li>
                            Задавай вопросы по теме футбола, тренерского процесса или клуба.
                        </li>
                        <li>
                            Сообщения без конкретного вопроса не учитываются.
                        </li>
                    </ul>
                </div>
                <div>
                    <b>3. Без спама и флуда</b>
                    <ul className="pl-8 pt-4 list-disc">
                        <li>
                            Можно задать только <b>один вопрос</b> за матч.
                        </li>
                        <li>
                            Повторяющиеся, бессмысленные или скопированные вопросы удаляются.
                        </li>
                    </ul>
                </div>
                <div>
                    <b>4. Без рекламы и ссылок</b>
                    <ul className="pl-8 pt-4 list-disc">
                        <li>
                            Нельзя включать рекламу, ссылки на сторонние сайты или продвигать товары и услуги.
                        </li>
                    </ul>
                </div>
                <div>
                    <b>5. Модерация и отбор</b>
                    <ul className="pl-8 pt-4 list-disc">
                        <li>
                            Вопросы, нарушающие правила, не будут переданы главному тренеру.
                        </li>
                        <li>
                            Наиболее интересные вопросы будет переданы главному тренеру и появятся в матчевой программке
                        </li>
                    </ul>
                </div>
            </div>
            <div className="text-lg text-white p-2 text-center py-4">
                Уважай других болельщиков и получай удовольствие от общения с клубом! <b>#ТопиЗаРодину</b>
            </div>
            <Button onClick={() => onClose!()}>
                Я всё понял
            </Button>
        </Popup >
    )
}