import { call, fork, put, takeLeading } from "redux-saga/effects";
import {
    loadAskCoach,
    loadAskCoachFailure,
    loadAskCoachSuccess,
    sendAskCoachRequest,
    sendAskCoachRequestFailure,
    sendAskCoachRequestSuccess,
} from "./reducer";
import { LoadAskCoachResponse, SendAskCoachRequestPayload, SendAskCoachRequestReponse } from "./types";
import * as askCoachTransport from "../../../api/transport/contests/askCoachTransport";
import { PayloadAction } from "@reduxjs/toolkit";

function* loadAskCoachWatcher() {
    yield takeLeading(loadAskCoach.type, loadAskCoachAsync);
}

function* loadAskCoachAsync({ payload }: PayloadAction<{
    userUuid: string,
}>) {
    try {
        const responseData: LoadAskCoachResponse = yield call(askCoachTransport.loadAskCoachConfig);

        yield put(loadAskCoachSuccess(responseData));
    } catch (error: any) {
        yield put(loadAskCoachFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее ' + error
            }
        ));
    }
}


function* sendAskCoachRequestWatcher() {
    yield takeLeading(sendAskCoachRequest.type, sendAskCoachRequestAsync);
}

function* sendAskCoachRequestAsync({ payload }: PayloadAction<SendAskCoachRequestPayload>) {
    try {
        //console.log('payload', payload);
        const responseData: SendAskCoachRequestReponse = yield call(askCoachTransport.sendAskCoachRequest, payload.text);

        yield put(sendAskCoachRequestSuccess(responseData));
    } catch (error: any) {
        yield put(sendAskCoachRequestFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

export function* askCoachSaga() {
    yield fork(loadAskCoachWatcher);
    yield fork(sendAskCoachRequestWatcher);
}
