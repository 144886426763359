import { call, fork, put, takeLeading } from "redux-saga/effects";
import {
    loadFixtureData,
    loadFixtureDataFailure,
    loadFixtureDataSuccess,
    loadMatchPlayerVote,
    loadMatchPlayerVoteFailure,
    loadMatchPlayerVoteSuccess,
    matchCheckIn,
    matchCheckInFailure,
    matchCheckInSuccess,
    resetPlayerSelection,
    selectPlayer,
    selectTicket,
    selectTicketFailure,
    selectTicketSuccess
} from "./reducer";
import { checkTicket, getVoteData } from "../../../api/transport/contests/matchVoteTransport";
import { FixtureShortInfoDto, MatchPlayerVoteResponse, TicketCheckResult, VoteStatus } from "./types";
import { getFixture } from "../../../api/transport/fixturesTransport";
import { checkIn } from "../../../api/transport/myAppTransport";

function* loadMatchPlayerVoteWatcher() {
    yield takeLeading(loadMatchPlayerVote.type, onLoadMatchVoteAsync);
}

function* onLoadMatchVoteAsync({ payload }: any) {
    try {
        const responseData: MatchPlayerVoteResponse = yield call(getVoteData, payload.uuid);

        if (responseData.status === VoteStatus.CLOSED || responseData.status === VoteStatus.CREATED) {
            localStorage.removeItem('selectedTicket');
            yield put(resetPlayerSelection());
        } else {
            const eventId = localStorage.getItem("eventId") || '';
            if (+responseData.eventId !== +eventId) {
                localStorage.removeItem('selectedTicket');
                localStorage.setItem("eventId", responseData.eventId.toString());
                yield put(resetPlayerSelection());
            }
        }

        yield put(loadMatchPlayerVoteSuccess({
           ...responseData,
        }));
    } catch (error: any) {
        yield put(loadMatchPlayerVoteFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* selectTicketWatcher() {
    yield takeLeading(selectTicket.type, selectTicketAsync);
}

function* selectTicketAsync({ payload }: any) {
    try {
        const responseData: boolean = yield call(checkTicket, payload);
        //console.log(responseData);

        yield put(selectTicketSuccess(responseData ? TicketCheckResult.OK : TicketCheckResult.ERROR));
    } catch (error: any) {
        yield put(selectTicketFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* selectPlayerWatcher() {
    yield takeLeading(selectPlayer.type, selectPlayerAsync);
}

function* selectPlayerAsync({ payload }: any) {
    yield localStorage.setItem('selectedPlayer', payload);
}

function* resetPlayerSelectionWatcher() {
    yield takeLeading(resetPlayerSelection.type, resetPlayerSelectionAsync);
}

function* resetPlayerSelectionAsync() {
    yield localStorage.removeItem('selectedPlayer');
}

function* loadFixtureDataWatcher() {
    yield takeLeading(loadFixtureData.type, loadFixtureDataAsync);
}

function* loadFixtureDataAsync({ payload }: any) {
    try {
        const responseData: FixtureShortInfoDto = yield call(getFixture, payload.matchId);

        yield put(loadFixtureDataSuccess({
            ...responseData,
        }));
    } catch (error: any) {
        yield put(loadFixtureDataFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* matchCheckInWatcher() {
    yield takeLeading(matchCheckIn.type, matchCheckInAsync);
}

function* matchCheckInAsync({ payload }: any) {
    try {
        const responseData: string = yield call(checkIn, payload.matchId);
        const checkInId = responseData;
        localStorage.setItem('checkedIn', `${payload.matchId}_${checkInId}`);
        yield put(matchCheckInSuccess({
            checkInId,
        }));
    } catch (error: any) {
        yield put(matchCheckInFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

export function* matchVoteSaga() {
    yield fork(loadMatchPlayerVoteWatcher);
    yield fork(selectTicketWatcher);
    yield fork(resetPlayerSelectionWatcher);
    yield fork(selectPlayerWatcher);
    yield fork(loadFixtureDataWatcher);
    yield fork(matchCheckInWatcher);
}