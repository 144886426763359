import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailurePayload, LoginPayload, RegistrationPayload, SyncTicketsSuccessPayload, UserProfile } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface AuthState {
    uuid: string,
    userProfile: UserProfile | null,
    authorized: boolean,
    loading?: boolean,
    error?: string,
}

// Define the initial state using that type
const initialState: AuthState = {
    uuid: '',
    userProfile: null,
    authorized: false,
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        register: (state, actions: PayloadAction<RegistrationPayload>) => {
            state.loading = true;
            state.error = '';
        },
        registerSuccess: (state, action: PayloadAction<UserProfile>) => {
            state.loading = false;
            state.authorized = true;
            state.uuid = action.payload.id;
            state.userProfile = action.payload;
            localStorage.setItem('userProfile', JSON.stringify(state.userProfile));
        },
        registerFailure: (state, action: PayloadAction<FailurePayload>) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        login: (state, _action: PayloadAction<LoginPayload>) => {
            state.loading = true;
            state.error = '';
        },
        loginSuccess: (state, action: PayloadAction<UserProfile>) => {
            state.loading = false;
            state.authorized = true;
            state.uuid = action.payload.id;
            state.userProfile = action.payload;
            localStorage.setItem('userProfile', JSON.stringify(state.userProfile));
        },
        loginFailure: (state, action: PayloadAction<FailurePayload>) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        loadUserProfile: (state, action: PayloadAction<{
            uuid?: string;
        }>) => {
            state.loading = true;
        },
        loadUserProfileSuccess: (state, action: PayloadAction<UserProfile>) => {
            state.loading = false;
            state.userProfile = action.payload;
            state.uuid = action.payload.id;
            state.authorized = true;
            //localStorage.setItem('userProfile', JSON.stringify(state.userProfile));
        },
        loadUserProfileFailure: (state, action: PayloadAction<{}>) => {
            state.loading = false;
        },
        logout: (state, action: PayloadAction<{}>) => {
            state.error = '';
            state.authorized = false;
            state.userProfile = null;
            localStorage.removeItem('userProfile');
        },
        syncTickets: (state, action: PayloadAction<{}>) => {
            state.loading = true;
        },
        syncTicketsSuccess: (state, action: PayloadAction<SyncTicketsSuccessPayload>) => {
            state.loading = false;
            if (state.userProfile) {
                state.userProfile.tickets = action.payload.tickets;
            }
        },
        syncTicketsFailure: (state, action: PayloadAction<{}>) => {
            state.loading = false;
        },
    }
})

export const {
    register,
    registerSuccess,
    registerFailure,
    login,
    loginSuccess,
    loginFailure,
    loadUserProfile,
    loadUserProfileSuccess,
    loadUserProfileFailure,
    logout,
    syncTickets,
    syncTicketsFailure,
    syncTicketsSuccess,
} = auth.actions;

export default auth.reducer;