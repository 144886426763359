import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useRef, useState } from 'react';
import { RoundButton as Button } from '../Button';
import { motion, AnimatePresence } from 'framer-motion';

// Настройка pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
    fileUrl: string;
    fullscreen?: boolean;
    onClose?: () => void;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, fullscreen, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [numPages, setNumPages] = useState<number>(0);

    const [scale, setScale] = useState<number>(1);
    const [isManualScale, setIsManualScale] = useState(false);

    const [isFullscreen, setIsFullscreen] = useState(fullscreen);

    // ResizeObserver для контейнера
    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current && !isManualScale) {
                const { width } = containerRef.current.getBoundingClientRect();
                setContainerWidth(width);
            }
        };

        updateWidth();

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [isManualScale]);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const zoomIn = () => {
        setIsManualScale(true);
        setScale((prev) => prev + 0.2);
    };

    const zoomOut = () => {
        setIsManualScale(true);
        setScale((prev) => (prev - 0.2 > 0.2 ? prev - 0.2 : 0.2));
    };

    const resetZoom = () => {
        setIsManualScale(false);
        setScale(1);
    };

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    return (
        <div className="flex flex-col items-center w-full">
            {/* Контролы */}
            <div className="flex gap-4 mb-4">
                <Button onClick={zoomOut} size='xs'>
                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 12h14" />
                    </svg>
                </Button>
                <Button onClick={zoomIn} size='xs'>
                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 12h14m-7 7V5" />
                    </svg>
                </Button>
                <Button onClick={resetZoom} size='xs'>
                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M16 4h4m0 0v4m0-4-5 5M8 20H4m0 0v-4m0 4 5-5" />
                    </svg>
                </Button>
                <Button onClick={toggleFullscreen} size='xs'>
                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 4H4m0 0v4m0-4 5 5m7-5h4m0 0v4m0-4-5 5M8 20H4m0 0v-4m0 4 5-5m7 5h4m0 0v-4m0 4-5-5" />
                    </svg>
                </Button>
            </div>

            {/* PDF Контейнер */}
            <div ref={containerRef} className="w-full max-w-[800px] mx-auto p-1 bg-gray-100 rounded shadow overflow-auto">
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} loading="Загрузка PDF...">
                    {Array.from(new Array(numPages), (_, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={!isManualScale ? containerWidth : undefined}
                            scale={isManualScale ? scale : undefined}
                            loading="Загрузка страницы..."
                            className="mb-4 shadow"
                        />
                    ))}
                </Document>
            </div>

            {/* Полноэкранный режим */}
            <AnimatePresence>
                {isFullscreen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 z-40 bg-black bg-opacity-90 flex flex-col items-center justify-center"
                    >
                        <div className="flex flex-row justify-between w-full max-w-screen-2xl p-2 px-3">
                            <div className="flex gap-4">
                                <Button onClick={zoomOut} size='xs'>
                                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 12h14" />
                                    </svg>
                                </Button>
                                <Button onClick={zoomIn} size='xs'>
                                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 12h14m-7 7V5" />
                                    </svg>
                                </Button>
                                <Button onClick={resetZoom} size='xs'>
                                    <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M16 4h4m0 0v4m0-4-5 5M8 20H4m0 0v-4m0 4 5-5" />
                                    </svg>
                                </Button>
                            </div>
                            <Button onClick={() => { toggleFullscreen(); onClose!() }} size='xs'>
                                <svg className="w-6 h-6 text-inherit inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18 17.94 6M18 18 6.06 6" />
                                </svg>
                            </Button>
                        </div>

                        <div
                            ref={containerRef}
                            className="w-full max-w-screen-2xl h-full overflow-y-auto rounded shadow-xl p-1"
                        >
                            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} loading="Загрузка PDF...">
                                {Array.from(new Array(numPages), (_, index) => (
                                    <Page
                                        key={`fullscreen_page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={!isManualScale ? containerWidth : undefined}
                                        scale={isManualScale ? scale : undefined}
                                        loading="Загрузка страницы..."
                                        className="mb-4 shadow"
                                    />
                                ))}
                            </Document>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PDFViewer;
