import { Link } from "react-router-dom";
import { useAppSelector } from "../../store";
import { selectAuth, selectTopIndex } from "../../store/selectors";
import { LinkButton } from "./components/Button";
import { RustatIcon } from "./components/RustatIcon";
import { PlayerCard } from "./PlayerCard";
import { useEffect, useState } from "react";
import { TopIndexStatus } from "../../store/slices/top-index/types";

let timerInterval: string | number | NodeJS.Timeout | undefined;

export const MainPage = () => {
    const auth = useAppSelector(selectAuth);
    const topIndex = useAppSelector(selectTopIndex);
    /*const [timeToUpdate, setTimeToUpdate] = useState<number>(0);

    useEffect(() => {
        const now = new Date().getTime()
        if (now >= topIndex.updateStatusTime || timeToUpdate < 0) {
            clearInterval(timerInterval);
            setTimeToUpdate(0);
            return;
        }

        if (topIndex.updateStatusTime && timeToUpdate === 0) {
            setTimeToUpdate(Math.floor((topIndex.updateStatusTime - new Date().getTime()) / 1000));
            timerInterval = setInterval(() => {
                setTimeToUpdate(timeToUpdate - 1);
            }, 1000);
        }
    }, [timeToUpdate, topIndex.updateStatusTime]);

    useEffect(() => {
        return () => clearInterval(timerInterval)
    }, []);*/

    let selectUrl = auth?.authorized ? "/top-index/selection" : "/auth/login?next=/top-index/selection";

    let content = (
        <>
            {/*<p>
                1. <Link to="/auth/login?backUrl=/top-index" className="text-sky-500">Войди</Link> в свой профиль
            </p>*/}
            <p>
                1. Выбери игрока
            </p>
            <p>
                2. Следи за каждым его действием на поле.
            </p>
            <p>
                3. Забирай набранный им Рустат индекс баллами на свой <Link to="/profile?next=/top-index" className="text-sky-500">баланс</Link> в приложении.
            </p>
            <div className="pt-8 w-full text-center">
                {topIndex.status <= TopIndexStatus.Wait && <div className="italic pb-4 text-gray-300">Ждём состав на матч</div>}
                <LinkButton url={selectUrl} disabled={topIndex.status !== TopIndexStatus.Selection}>К выбору игрока</LinkButton>
            </div>
        </>
    );

    if (topIndex.selectedPlayerId) {
        const playerInfo = topIndex.players?.find(p => p.id === topIndex.selectedPlayerId);

        if (!playerInfo) {
            content = <div />;
        } else {
            content = (
                <>
                    <h3 className="text-xl text-center uppercase pb-1">Твой выбор:</h3>
                    <div className="w-2/3 mx-auto">
                        <PlayerCard
                            name={playerInfo?.name}
                            index={playerInfo.indexScoreAverage}
                            photoUrl={playerInfo?.photoUrl}
                        />
                    </div>
                    <p className="text-center pt-2">
                        Следи за своим игроком и ожидай подсчёта рейтинга по итогам матча
                    </p>
                </>
            );
        }
    }

    if (topIndex.status === TopIndexStatus.Results) {
        const playerInfo = topIndex.players?.find(p => p.id === topIndex.selectedPlayerId);
        const bestPlayerInfo = topIndex.topScorePlayer;
        const isMyTheBest = bestPlayerInfo?.id === topIndex.selectedPlayerId;

        if (!bestPlayerInfo) {
            content = <div />;
        } else {
            content = (
                <>
                    <h3 className="text-xl text-center uppercase pb-1">Луший игрок</h3>
                    <div className="w-2/3 mx-auto">
                        <PlayerCard
                            name={bestPlayerInfo.name}
                            index={bestPlayerInfo.indexScoreLast}
                            photoUrl={bestPlayerInfo.photoUrl}
                        />
                    </div>
                    {!isMyTheBest && !!playerInfo && <p className="text-center pt-2">
                        Выбранный тобой <b>{playerInfo?.name || ''}</b> не стал лучшим, но набрал <b>{playerInfo?.indexScoreLast || 0}</b> баллов, они
                        будут начислены на твой бонусный счёт
                    </p>}
                    {isMyTheBest && <p className="text-center pt-2">
                        Ты сделал правильный выбор, набранные игроком {bestPlayerInfo?.indexScoreLast || 0} баллов
                        будут начислены на твой бонусный счёт
                    </p>}
                </>
            );
        }
    } else if (topIndex.status === TopIndexStatus.Scoring) {
        if (!topIndex.selectedPlayerId) {
            content = (
                <>
                <h3 className="text-xl text-center uppercase pb-1">Ждем результатов</h3>
                    <p className="text-center pt-2">
                        Ты не успел выбрать игрока на этот матч, ждём тебя в следующий раз
                    </p>
                </>
            )
        }
    }

    return (
        <div className="bg-slate-900 text-white w-full min-h-full pt-2 pb-16">
            <div className="p-4 flex flex-col max-w-96 mx-auto gap-3">
                <h2 className="text-xl text-center uppercase pb-2"><RustatIcon className="inline w-7 h-7 mb-1 mr-1" />рустат игрок матча</h2>
                <h3 className="text-2xl font-bold text-center uppercase pb-3">{topIndex.matchName}</h3>
                {content}
            </div>
        </div>
    );
}