import { FC, useEffect, useState } from "react";
import { CloseIcon } from "./Icons";
import { AnimatePresence, motion } from "framer-motion";
interface PopupProps {
    children: React.ReactNode;
    onClose?: () => void;
    title?: string;
}

export const Popup: FC<PopupProps> = ({ children, onClose, title }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);

        return () => {
            setShow(false);
        };
    }, []);

    return (
        <div className="transition-all fixed top-0 bottom-0 left-0 right-0 z-40 w-full transparent flex flex-col gap-2 justify-center items-center sm:bg-slate-950/50 sm:p-8">
            <motion.div
                initial={{ opacity: 1, y: '100%' }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="relative w-full sm:max-w-96 bg-sky-950 flex flex-col justify-between h-full p-2 sm:rounded sm:p-4 overflow-auto"
            >
                {onClose && <div className="absolute right-3 top-3 cursor-pointer" onClick={onClose}>
                    <CloseIcon />
                </div>}
                {title && <h2 className="text-white text-xl font-semibold py-4 pt-6 px-2 text-center">{title}</h2>}
                {children}
            </motion.div>
        </div>
    )
};