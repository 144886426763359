import { motion } from "framer-motion";
import { FC, ReactNode } from "react";
import { PageHeader } from "./PageHeader";

interface InternalPageProps {
    title?: string;
    onBack: () => void;
    onClose?: () => void;
    children: ReactNode;
}

export const InternalPage: FC<InternalPageProps> = ({ children, title, onBack, onClose }) => {
    const onBackHandler = () => {
        onBack();
    }

    return (
        <motion.div
            className="w-full h-screen"
            initial={{
                x: '100%',
                opacity: 0,
            }}
            animate={{
                x: 0,
                opacity: 1,
            }}
            transition={{
                duration: 0.3,
            }}
        >
            <PageHeader title={title} onBack={onBackHandler} onClose={onClose}/>
            <div className="w-full sm:max-w-96 mx-auto">
                {children}
            </div>
        </motion.div>
    )

}