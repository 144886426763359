import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const TicketsPage: React.FC = () => {
   const navigate = useNavigate();
    useEffect(() => {
        window.open('https://fcrodina.com/ticket-app/', '_blank');
        navigate('/');
    }, []);
    return (
        <div>
        </div>
    );
}