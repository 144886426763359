import { Link, useNavigate } from "react-router-dom"
import { InternalPage } from "../../../components/layout/InternalPage";
import { useAppSelector } from "../../../store";
import { selectUserProfile } from "../../../store/selectors";
import TicketsManager from "./TicketsManager";
import PdfBarcodeScanner from "./PdfBarcodeScaner";
import QRScanManager from "./QRScanManager";
import { SyncronizedTickets } from "./SyncronizedTickets";
import { useNavigateBack } from "../../../hooks";

export const TicketsWalletPage = () => {
    const navigateBack = useNavigateBack();
    const profile = useAppSelector(selectUserProfile);

    return (
        <InternalPage
            onBack={() => navigateBack()}
            title="Твои билеты"
        >
            <div className="p-2 pb-20 flex flex-col gap-2">
                <SyncronizedTickets />
                <TicketsManager />
                {/*<PdfBarcodeScanner />
                <QRScanManager />*/}
            </div>
        </InternalPage>
    )
}