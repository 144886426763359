import { FC, useState } from "react";
import { TimerCard } from "../TimerCard";
import { useAppSelector } from "../../../store";
import { selectMatchPlayerVote } from "../../../store/selectors";
import { VoteStatus } from "../../../store/slices/matchPlayerVote/types";
import { RoundButton as Button } from "../../../components/Button";
import { MatchBanner } from "../MatchBanner";
import { Popup } from "../../../components/Popup";
import { CloseIcon } from "../../../components/Icons";
import { RulesPopup } from "../RulesPopup";

interface MainScreenProps {
    voteStart: () => void;
    timerText: string;
}

export const MainScreen: FC<MainScreenProps> = ({
    voteStart,
    timerText
}) => {
    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);
    const [showDescription, setShowDescription] = useState(false);
    return (
        <>
            <div>
                <h1 className="text-xl text-center font-bold">Лучший игрок матча</h1>
                {matchPlayerVote.matchName && <h1 className="text-xl text-center font-bold">«{matchPlayerVote.matchName}»</h1>}
            </div>
            <MatchBanner />
            <Button onClick={voteStart} disabled={matchPlayerVote.status !== VoteStatus.STARTED}>Голосовать</Button>
            {(matchPlayerVote.status === VoteStatus.CLOSED || (matchPlayerVote.status === VoteStatus.CREATED/* && !matchPlayerVote.startDate*/)) &&
                <div className="text-center font-semibold">
                    <div className="text-center">
                        Голосование начнется не позже 75-й минуты матча.
                    </div>
                    <div className="text-center">
                        Выбирай игрока и получишь шанс лично вручить ему приз. <span className="text-blue-500 cursor-pointer" onClick={() => setShowDescription(true)}>Подробнее</span>
                    </div>
                </div>}
            {matchPlayerVote.status === VoteStatus.CREATED && <div>
                <TimerCard
                    timeText={timerText}
                    title="До начала голосования"
                />
            </div>}
            <RulesPopup show={showDescription} onClose={() => setShowDescription(false)}/>
        </>
    );
}
