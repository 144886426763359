export const getEventInfo = (id: string) => {
    const MATCH_INFOS = [
        {
            eventId: '7824',
            showId: '617453',
            eventName: 'РОДИНА - СОЧИ',
            eventDate: '2025-04-06'
        },
        {
            eventId: '7821',
            showId: '575143',
            eventName: 'РОДИНА - АЛАНИЯ',
            eventDate: '2025-03-16',
        },
        {
            eventId: '7819',
            showId: '575129',
            eventName: 'РОДИНА - ТЮМЕНЬ',
            eventDate: '2025-03-02'
        },
        {
            eventId: '7581',
            showId: '546006',
            eventName: 'РОДИНА - НЕФТЕХИМИК',
            eventDate: '2024-12-01'
        },
        {
            eventId: '7579',
            showId: '546003',
            eventName: 'РОДИНА - ЧЕРНОМОРЕЦ',
            eventDate: '2024-11-17'
        },
        {
            eventId: '7572',
            showId: '532643',
            eventName: 'РОДИНА - АРСЕНАЛ',
            eventDate: '2024-10-05'
        },
        {
            eventId: '7858',
            showId: '549423',
            eventName: 'РОДИНА - СПАРТАК (Кострома)',
            eventDate: '2024-10-16'
        }
    ];

    id = id + '';

    let matchData = MATCH_INFOS.find(m => m.eventId === id);

    if (!matchData) {
        matchData = MATCH_INFOS.find(m => m.showId === id);
    }

    return matchData;
}