import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ActionLink {
    title?: string;
    url: string;
}

interface SmallCardsSectionProps {
    children?: ReactNode;
    title?: string;
    actionLink?: ActionLink;
}
export const SmallCardsSection: FC<SmallCardsSectionProps> = ({
    children,
    title,
    actionLink,
}) => {
    return (
        <div className="w-full pb-2">
            <div className="flex flex-row justify-between items-end pb-2">
                <h2 className="text-xl font-bold uppercase text-sky-950 dark:text-white">
                    {title}
                </h2>
                {actionLink && <Link to={actionLink.url} className="bg-slate-300 dark:bg-slate-700 text-sm rounded-full font-medium px-4 py-1 active:scale-95 transition-all">
                    {actionLink?.title}
                </Link>}
            </div>
            <div className="flex flex-row overflow-x-auto py-2 gap-3">
                {children}
            </div>
        </div>
    )
}