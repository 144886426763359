import { FC } from "react";
import { Popup } from "../../components/Popup";
import { Button } from "../../components/Button";

interface RulesPopupProps {
    show?: boolean;
    onClose?: () => void;
}

export const RulesPopup: FC<RulesPopupProps> = ({
    show,
    onClose,
}) => {
    if (!show) {
        return null;
    }

    return (
        <Popup
            onClose={() => onClose!()}
            title="Чтобы принять участие в голосовании тебе нужно"
        >
            <div className="text-lg text-white flex flex-col gap-2 p-2">
                <div>
                    1. ⁠Оформить билет
                </div>
                <div>
                    2. Сохранить номер билета
                </div>
                <div>
                    3. ⁠Прийти на матч
                </div>
                <div>
                    4. ⁠Открыть приложение "Родные" после 75-й мин. матча
                </div>
                <div>
                    5. ⁠Проголосовать за лучшего игрока (потребуется номер билета)
                </div>
            </div>
            <div className="text-lg text-white p-2">
                Из проголосовавших за лучшего игрока случайным образом будет выбран болельщик, который получит возможность вручить приз игроку лично.
            </div>
            <div className="flex flex-col gap-2 items-center">
                <Button href="https://fcrodina.com/ticket-app/" title="БИЛЕТЫ" type="secondary" className="transition-all border-2 border-solid border-white text-center bg-white !text-sky-950 w-full p-3 text-2xl font-bold hover:bg-sky-950 hover:!text-white" />
                <Button href="https://fcrodina.com/matchday/" title="Матчдей" type="link" className="transition-all border-2 border-solid border-transparent text-center text-white w-full p-3 text-2xl font-bold hover:no-underline hover:border-white" />
            </div>
        </Popup >
    )
}