import { call, fork, put, takeLeading } from "redux-saga/effects";
import {
    loadUserProfile,
    loadUserProfileFailure,
    loadUserProfileSuccess,
    login,
    loginSuccess,
    loginFailure,
    register,
    registerSuccess,
    registerFailure,
    syncTickets,
    syncTicketsSuccess,
    syncTicketsFailure,
} from "./reducer";
import { GetUserProfileResponse, LoginPayload, LoginResponse, RegistrationResponse, SyncTicketsSuccessResponse } from "./types";
import * as authTransport from "../../../api/transport/authTransport";
import { PayloadAction } from "@reduxjs/toolkit";

function* registerUserWatcher() {
    yield takeLeading(register.type, registerAsync);
}

function* registerAsync({ payload }: any) {
    try {
        const responseData: RegistrationResponse = yield call(authTransport.register, { ...payload });

        yield put(registerSuccess({
            ...responseData.profile,
        }));
    } catch (error: any) {
        yield put(registerFailure(
            {
                error: 'Не удалось зарегистрироваться, возможно пользователь уже существует'
            }
        ));
    }
}

function* loginWatcher() {
    yield takeLeading(login.type, loginAsync);
}

function* loginAsync({ payload }: PayloadAction<LoginPayload>) {
    try {
        /*const responseData: LoginResponse = */yield call(authTransport.login, payload.login, payload.password);

        const profileResponse: GetUserProfileResponse = yield call(authTransport.getProfile);

        yield put(loginSuccess({
            ...profileResponse?.user,
        }));
    } catch (error: any) {
        yield put(loginFailure(
            {
                error: 'Попытка входа не удалась'
            }
        ));
    }
}

function* loadUserProfileWatcher() {
    yield takeLeading(loadUserProfile.type, onLoadUserProfileAsync);
}

function* onLoadUserProfileAsync({ payload }: any) {
    try {
        const responseData: GetUserProfileResponse = yield call(authTransport.getProfile);

        //console.log('responseData:', responseData);

        yield put(loadUserProfileSuccess({
            ...responseData.user,
        }));
    } catch (error: any) {
        yield put(loadUserProfileFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* syncTicketsWatcher() {
    yield takeLeading(syncTickets.type, onSyncTicketsAsync);
}

function* onSyncTicketsAsync({ payload }: any) {
    try {
        const lastSync = localStorage.getItem('ticketsSyncTime') || 0;
        const now = new Date().getTime();

        if (lastSync) {
            if (((now - +lastSync) / 60000) < 60) {
                yield put(syncTicketsFailure(
                    {
                        error: 'Таймаут синхронизации'
                    }
                ));
                return;
            }
        }

        const responseData: SyncTicketsSuccessResponse = yield call(authTransport.refreshTickets);

        localStorage.setItem('ticketsSyncTime', now.toString());

        yield put(syncTicketsSuccess({
            ...responseData,
        }));
    } catch (error: any) {
        yield put(syncTicketsFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

export function* authSaga() {
    yield fork(loadUserProfileWatcher);
    yield fork(loginWatcher);
    yield fork(registerUserWatcher);
    yield fork(syncTicketsWatcher);
}
