import React, { useState } from 'react';
import { useFileStorage, StoredFile } from '../../../hooks/useFileStorage';
import PDFViewer from '../../../components/pdf-viewer/PDFViewer';
import TicketPickerButton from './TicketPickerButton';
import ImageViewer from '../../../components/pdf-viewer/ImageViewer';

const TicketsManager: React.FC = () => {
    const { fileList, saveFile, deleteFile, getFile } = useFileStorage();

    const [tagsInput, setTagsInput] = useState<string>('');
    const [previewFile, setPreviewFile] = useState<StoredFile | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleFileChange = async (file: File) => {
        if (!file) return;

        const tagsArray = tagsInput
            .split(',')
            .map((tag) => tag.trim())
            .filter(Boolean);

        await saveFile(file, 'Билет', tagsArray, '-');

        setTagsInput('');
    };

    const handleDelete = async (fileName: string) => {
        if (globalThis.confirm(`Удалить билет "${fileName}"?`)) {
            await deleteFile(fileName);
            if (previewFile?.name === fileName) {
                setPreviewFile(null);
                if (previewUrl) {
                    URL.revokeObjectURL(previewUrl);
                    setPreviewUrl(null);
                }
            }
        }
    };

    const handlePreview = async (fileName: string) => {
        const file = await getFile(fileName);
        if (!file) return;

        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }

        const blob = new Blob([file.content], { type: file.type });
        const url = URL.createObjectURL(blob);

        setPreviewFile(file);
        setPreviewUrl(url);
    };

    const handlePreviewClose = () => {
        setPreviewFile(null);
        setPreviewUrl(null);
    }

    const handleDownload = () => {
        if (!previewFile || !previewUrl) return;

        const a = document.createElement('a');
        a.href = previewUrl;
        a.download = previewFile.name;
        a.click();
    };

    return (
        <div className="flex flex-col gap-4 mx-auto w-full max-w-full dark:text-white">
            <h2 className="text-2xl font-bold">Загруженные</h2>

            <TicketPickerButton
                label='Добавить'
                onFileSelected={handleFileChange}
                accept='application/pdf,image/*'
            />

            {/* Выбор файла */}
            {/*<input
                type="file"
                onChange={handleFileChange}
                className="rounded"
            />*/}

            <div className="pt-2">
                {/*<h3 className="text-lg font-semibold mb-2">Загруженные билеты:</h3>*/}
                {fileList.length === 0 && <p className="text-gray-500 dark:text-gray-400 text-center">Нет билетов</p>}

                <ul className="flex flex-col gap-3">
                    {fileList.map((file) => (
                        <li
                            key={file.name}
                            className="flex justify-between items-center min-h-20 bg-gray-100 rounded-lg px-3 py-2 shadow text-sky-950"
                        >
                            <div>
                                <div className="font-semibold text-ellipsis overflow-hidden max-w-40">{file.name}</div>
                                {/*<div className="text-sm text-gray-500">
                                    {file.category} | {file.tags.join(', ')} |{' '}
                                    {(file.size / 1024 / 1024).toFixed(2)}MB
                                </div>*/}
                                <div className="text-xs text-gray-400">
                                    Добавлен: {new Date(file.uploadDate).toLocaleString()} {/*|{' '}*/}
                                    {/*file.uploadedBy*/}
                                </div>
                            </div>
                            <div className="flex gap-2 min-w-20">
                                <button
                                    onClick={() => handlePreview(file.name)}
                                    className="bg-slate-300 text-blue-900 p-2 rounded-full hover:bg-blue-100 active:scale-95 transition"
                                >
                                    <svg className="w-6 h-6 text-inherit" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeWidth="1" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                                        <path stroke="currentColor" strokeWidth="1" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>

                                </button>
                                <button
                                    onClick={() => handleDelete(file.name)}
                                    className="bg-slate-300 text-red-500 p-2 rounded-full hover:bg-red-100 active:scale-95 transition"
                                >
                                    <svg className="w-6 h-6 text-inherit" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                    </svg>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Превью */}
            {previewFile && previewUrl && (
                <div className="mt-6 p-4 border rounded-lg bg-white shadow text-sky-950">
                    <h3 className="text-lg font-semibold mb-2">
                        Билет: {previewFile.name}
                    </h3>

                    {previewFile.type.startsWith('image/') && (
                        <ImageViewer
                            fileUrl={previewUrl}
                            fullscreen
                            onClose={handlePreviewClose}
                        />
                    )}

                    {previewFile.type === 'application/pdf' && (
                        <PDFViewer fileUrl={previewUrl} fullscreen onClose={handlePreviewClose} />
                    )}

                    <button
                        onClick={handleDownload}
                        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
                    >
                        Скачать
                    </button>
                </div>
            )}
        </div>
    );
};

export default TicketsManager;
