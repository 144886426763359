import React, { useRef } from 'react';
import { RoundButton as Button } from "../../../components/Button";

type TicketPickerButtonProps = {
    onFileSelected: (file: File) => void;
    accept?: string;
    multiple?: boolean;
    label?: string;
};

const TicketPickerButton: React.FC<TicketPickerButtonProps> = ({
    onFileSelected,
    accept = '',
    multiple = false,
    label = 'Выбрать файл'
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = () => {
        inputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        console.log('handle file change')

        if (!files || files.length === 0) return;

        console.log('has file')

        if (multiple) {
            Array.from(files).forEach((file) => onFileSelected(file));
        } else {
            onFileSelected(files[0]);
        }

        // Сброс значения для повторного выбора того же файла
        event.target.value = '';
    };

    return (
        <div>
            <Button
                onClick={handleButtonClick}
            >
                {label}
            </Button>

            <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                accept={accept}
                multiple={multiple}
                className="hidden"
            />
        </div>
    );
};

export default TicketPickerButton;
