import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginForm, LoginFormValues } from "./login/LoginForm"
import { AuthPage } from "./types";
import { RegistrationForm, RegistrationFormValues } from "./login/RegistrationForm";
import { useAppDispatch, useAppSelector } from "../../store";
import { login, register } from "../../store/slices/auth/reducer";
import { selectAuth } from "../../store/selectors";
import { InternalPage } from "../../components/layout/InternalPage";
import { useEffect } from "react";

export const LoginPage = () => {
    const dispatch = useAppDispatch();
    const onFormFinish = (values: LoginFormValues) => {
        dispatch(login(values));
    }

    const navigate = useNavigate();

    const authData = useAppSelector(selectAuth);

    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const nextUrl = searchParams.get('next');

    useEffect(() => {
        if (authData.authorized) {
            if (!nextUrl) {
                navigate('/profile' + search);
            } else {
                navigate(nextUrl);
            }
        }
    }, [authData.authorized, navigate, nextUrl, search])

    return (
        <div className="w-full max-w-96 h-full p-4 dark:bg-sky-950 dark:text-white">
            <LoginForm onFormFinish={onFormFinish} registrationLink={"/auth/register" + search}/>
            {authData?.error && <div className="text-red-500 p-2 text-center">{authData.error}</div>}
        </div>
    )
}

export const RegistrationPage = () => {
    const dispatch = useAppDispatch();
    const onFormFinish = (values: RegistrationFormValues) => {
        dispatch(register({
            name: values.name,
            password: values.password,
            email: values.email,
        }));
    }

    const navigate = useNavigate();

    const authData = useAppSelector(selectAuth);

    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const nextUrl = searchParams.get('next');

    useEffect(() => {
        if (authData.authorized) {
            if (!nextUrl) {
                navigate('/profile' + search);
            } else {
                navigate(nextUrl, { replace: true });
            }
        }
    }, [authData.authorized, navigate, nextUrl, search]);

    return (
        <div className="w-full max-w-96 h-full p-4 dark:bg-sky-950 dark:text-white">
            <h1 className="text-2xl font-bold py-4">Давай знакомиться! </h1>
            <RegistrationForm onFormFinished={onFormFinish} />
            {authData?.error && <div className="text-red-500 p-2 text-center">{authData.error}</div>}
        </div>
    )

}

export const AuthPages = () => {
    const { page } = useParams<{ page?: AuthPage }>();
    const navigate = useNavigate();

    let pageContent = null;

    if (page === AuthPage.Login) {
        pageContent = <LoginPage />;
    } else if (page === AuthPage.Register) {
        pageContent = <RegistrationPage />;
    }

    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const backUrl = searchParams.get('backUrl') || searchParams.get('next') || '/';

    console.log(backUrl);

    return (
        <InternalPage
            onBack={() => navigate(backUrl)}
        >
            {pageContent}
        </InternalPage>
    )

    return (null);
}