import { PageContainer } from "../../components/layout";
import { ActualMatch } from "./ActualMatch";
import { AskCoach } from "./AskCoach";
import { ContestList } from "./ContestList";
import { MerchList } from "./MerchList";
import { StoriesList } from "./StoriesList";
import { TicketsWallet } from "./TicketsWallet";

export const HomePage = () => {
    return (
        <PageContainer>
            {/*<h1 className="text-3xl font-bold italic pt-2 pb-6 self-start">Здравствуй, родной!</h1>*/}
            <StoriesList />
            <ActualMatch />
            <TicketsWallet />
            <ContestList />
            <AskCoach />
            <MerchList />
        </PageContainer>
    )
}