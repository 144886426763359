
"use client";

import { Button, Label, TextInput } from "flowbite-react";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

export interface LoginFormValues {
    login: string;
    password: string;
}

interface LoginFormProps {
    onFormFinish?: (values: LoginFormValues) => void;
    registrationLink?: string;
}
export const LoginForm: FC<LoginFormProps> = ({
    onFormFinish,
    registrationLink = "/auth/register",
}) => {
    const [formValues, setFormValues] = useState<LoginFormValues>({ login: "", password: "", });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (onFormFinish) {
            onFormFinish(formValues)
        }
    }

    return (
        <form className="flex w-full h-full flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email1" value="E-mail" />
                </div>
                <TextInput id="email1" name="login" type="email" placeholder="name@mailserver.com" required onChange={handleChange}/>
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password1" value="Пароль" />
                </div>
                <TextInput id="password1" name="password" type="password" required onChange={handleChange} />
            </div>
            <div className="flex items-center gap-2">
                <Link to={registrationLink} className="text-sm text-sky-700 dark:text-sky-500">Регистрация</Link>
                {/*<Checkbox id="remember" />
                <Label htmlFor="remember">Remember me</Label>*/}
            </div>
            <Button type="submit" className="bg-sky-950 hover:bg-sky-900 focus:bg-sky-950 active:bg-sky-800">Войти</Button>
        </form>
    );
}
