import { Link } from "react-router-dom";
import { useAppSelector } from "../../store";
import { selectAuth } from "../../store/selectors";

export const Landing = () => {
    const auth = useAppSelector(selectAuth);

    return (
        <div className="bg-slate-900 text-white w-full min-h-full pt-8 pb-16">
            <div className="p-4 py-4 flex flex-col max-w-96 mx-auto gap-3">
                {/*<h1 className="text-2xl font-bold text-center uppercase pb-4">Топ-индекс</h1>*/}
                <p>
                    1. <Link to="/auth/login?next=/top-index">Войди</Link> в свой профиль
                </p>
                <p>
                    2. Выбери игрока
                </p>
                <p>
                    3. Следи за каждым его действием на поле.
                </p>
                <p>
                    4. Забирай набранный им Рустат индекс баллами на свой <Link to="/profile?next=/top-index" className="text-sky-500">баланс</Link> в приложении.
                </p>
                <p className="italic text-xs">
                    Рустат индекс - это совокупный показатель технико-тактических действий игрока по итогам сыгранного им матча.
                </p>

                <p className="text-center text-2xl pt-4">
                    Будет доступно во время матча
                </p>
                <p className="text-center text-2xl font-bold">
                    Родина - Тюмень
                </p>
                <div className="pt-8 text-center">
                    <a
                        href="https://fcrodina.com/ticket-app/"
                        className="w-full rounded-full bg-white text-slate-950 text-xl text-center p-4 px-8 uppercase font-bold !hover:bg-white/75 !focus:bg-white/75 focus:ring-0"
                    >
                        Купить билет
                    </a>
                </div>
            </div>
        </div>
    );
}