import { FC, ReactNode } from "react";

interface SmallCardProps {
    children?: ReactNode;
    className?: string;
}

export const SmallCard: FC<SmallCardProps> = ({
    children,
    className,
}) => {
    let classNames = "transition-all active:scale-90 min-w-36 h-24 !rounded-2xl shadow-none !bg-sky-600 flex items-center justify-center font-bold text-white";

    if (className) {
        classNames += ' ' + className;
    }

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}
