import { all, call } from 'redux-saga/effects';
import { matchVoteSaga } from '../slices/matchPlayerVote/sagas';  // Example saga
import { authSaga } from '../slices/auth/sagas';
import { topIndexSaga } from '../slices/top-index/sagas';
import { configSaga } from '../slices/config/sagas';
import { askCoachSaga } from '../slices/ask-coach/sagas';

// The root saga combines all other sagas
export function* rootSaga() {
  yield all([
    call(matchVoteSaga),
    call(authSaga),
    call(topIndexSaga),
    call(configSaga),
    call(askCoachSaga),
    // Add other sagas here
  ]);
}

export default rootSaga;
