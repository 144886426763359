import configReducer from '../slices/config/reducer';
import matchPlayerVoteReducer from '../slices/matchPlayerVote/reducer';
import authReducer from '../slices/auth/reducer';
import topIndexReducer from '../slices/top-index/reducer';
import askCoachReducer from '../slices/ask-coach/reducer';
import { combineReducers } from '@reduxjs/toolkit';

export const rootReducer = combineReducers({
    matchPlayerVote: matchPlayerVoteReducer,
    auth: authReducer,
    topIndex: topIndexReducer,
    config: configReducer,
    askCoach: askCoachReducer,
    //calendar: calendarReducer,
    //team: teamReducer,
    //common: commonReducer,
});