import { BACKEND_URL } from "./consts";

const CONFIGURATION_BASE_URL = `${BACKEND_URL}/config`;

export const getAppConfig = async () => {
    const config = await fetch(CONFIGURATION_BASE_URL + '/myapp', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await config.json();

    return JSON.parse(data.value);
}
