import { fetchSecured } from "../../fetchSecured";
import { API_GW_URL } from "../consts";

const TRANSPORT_BASE_URL = `${API_GW_URL}/myapp/ask-coach`;

export const loadAskCoachConfig = async () => {
    const response = await fetchSecured(`${TRANSPORT_BASE_URL}/config`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    return response;
}

export const sendAskCoachRequest = async (text: string) => {
    const response = await fetchSecured(`${TRANSPORT_BASE_URL}/request`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ text })
    });

    return response;
}