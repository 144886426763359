import { FC } from "react";
import { BackIcon, CloseMenuicon } from "../Icons";

interface PageHeaderProps {
    title?: string;
    onBack: () => void;
    onClose?: () => void;
}

export const PageHeader: FC<PageHeaderProps> = ({ title, onBack, onClose }) => {
    return (
        <div className="flex flex-row justify-between items-center p-2 sticky top-0 bg-sky-950 text-white">
            <div onClick={onBack} className="hover:scale-105 cursor-pointer">
                <BackIcon />
            </div>
            <div className="uppercase font-bold">{title}</div>
            <div className="min-w-[40px]">
                {false && onClose && <div onClick={onClose} className="hover:scale-105 cursor-pointer">
                    <CloseMenuicon />
                </div>}
            </div>
        </div>
    );
}
