import { useEffect, useState } from "react";
import { Button } from "../../top-index/components/Button";
import { confirmEmailAddress } from "../../../api/transport/authTransport";

export const ConfirmEmailPage = () => {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams?.get('token');
        if (!token) {
            setLoading(false);
            if (searchParams?.has('success')) {
                setSuccess(true);
            }
            return;
        }

        confirmEmailAddress('', token).then(
            (data) => {
                //console.log('data: ', data);
                //setSuccess(true);
                window.location.replace(window.location.pathname + '?success');
            }
        ).catch(
            (e) =>{
                console.error('error: ', e);
                //setSuccess(false);
                window.location.replace(window.location.pathname);
            }
        ).finally(() => {
            //setLoading(false);
        })
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center bg-slate-950 text-white text-xl w-full h-screen">
            </div>
        )
    }

    return (
        <div className="p-4 flex flex-col gap-4 items-center justify-center bg-slate-950 text-white text-3xl text-center w-full h-screen">
            {success ? 'E-mail подтверждён' : 'Подтверждение не удалось'}
            <Button onClick={() => window.location.replace('/')}>К приложению</Button>
        </div>
    )
}