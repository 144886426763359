import { useNavigate } from "react-router-dom";
import { Popup } from "../../components/Popup";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectMatchPlayerVote } from "../../store/selectors";
import { timeRemaining } from "../mvp-vote/utils";
import { matchCheckIn } from "../../store/slices/matchPlayerVote/reducer";
import { RoundButton as Button } from "../../components/Button";

let timerInterval: any = 0;

export const CheckInPage = () => {
    const navigate = useNavigate();
    const mvpVote = useAppSelector(selectMatchPlayerVote);
    const dispatch = useAppDispatch();
    const [timerText, setTimerText] = useState('');
    const [voteStarted, setVoteStarted] = useState(false);

    useEffect(() => {
        if (!mvpVote.matchId || mvpVote.checkedIn) {
            return;
        }

        dispatch(matchCheckIn({
            matchId: mvpVote.matchId.toString(),
        }));
        return () => {
            clearInterval(timerInterval);
        }
    }, [dispatch, mvpVote.checkedIn, mvpVote.matchId]);

    const onTimerUpdate = (target?: number) => {
        if (!target) {
            return;
        }

        const txt = timeRemaining(target, '')
        if (!txt) {
            setVoteStarted(true);
        }

        setTimerText(timeRemaining(target, ''));
    }

    useEffect(() => {
        clearInterval(timerInterval);
        if (mvpVote.startDate) {
            timerInterval = setInterval(() => {
                onTimerUpdate(mvpVote.startDate);
            }, 1000);
        }
        onTimerUpdate(mvpVote.startDate);
    }, [mvpVote.startDate]);

    const onClose = () => navigate('/', { replace: true });

    return (
        <Popup
            onClose={onClose}
            title="Добро пожаловать на Родину!"
        >
            <div className="text-xl text-white text-center flex flex-col gap-2 p-2 h-full justify-between">
                <div>
                    Голосование за лучшего игрока матча начинается с 75-й минуты <br />
                    #топизародину и своего фаворита в приложении "Родные"
                </div>
                {!!mvpVote.checkedIn && <div>
                    <div className="pb-4 text-2xl">Твой номер</div>
                    <div className="text-7xl p-8 w-fit mx-auto rounded-full bg-slate-950 text-white border-4 border-white border-solid">{mvpVote.checkedIn}</div>
                </div>}
                <div>
                    <div>
                        До начала голосования:
                    </div>
                    <div>
                        {timerText}
                    </div>
                </div>
                {voteStarted && <Button onClick={() => navigate('/vote', { replace: true })}>
                    Голосовать
                </Button>}
                {!voteStarted && <Button onClick={onClose}>
                    Хорошо
                </Button>}
            </div>
        </Popup>
    );
}
