import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectUserProfile } from "../../../store/selectors";
import { RoundButton as Button } from "../../../components/Button";
import { getEventInfo } from "../../../utils/tickets";
import { syncTickets } from "../../../store/slices/auth/reducer";
import { Card } from "../../../components/Card";

export const SyncronizedTickets = () => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectUserProfile);

    if (!profile?.confirmed) {
        return (
            <Card className="text-sky-950 !p-4 text-lg">
                <Link to='/auth/login?next=/profile/tickets' replace className="text-blue-500">Зарегистрируйся</Link> и подтверди привязанный к профилю e-mail, чтобы билеты подгружались автоматически
            </Card>
        );
    }

    return (
        <>
            <Button
                onClick={() => dispatch(syncTickets({}))}
            >
                Обновить
            </Button>
            <span className="text-center italic text-sm -mt-1">Синхронизация возможна не чаще, чем раз в 30 минут</span>
            <div className="w-full flex flex-col gap-3 pt-2">
                {!profile?.tickets?.length && <p className="text-gray-500 dark:text-gray-400 text-center py-8">Нет билетов</p>}
                {profile?.tickets?.map(t => {
                    const eventData = getEventInfo(t.eventId);

                    return (
                        <div key={t.barcode} className="flex justify-between items-center min-h-20 bg-gray-100 rounded-2xl px-3 py-2 shadow text-sky-950">
                            <div>
                                <div className="font-semibold text-ellipsis overflow-hidden max-w-full">{eventData?.eventName || t.barcode}</div>
                                <div className="font-medium text-ellipsis overflow-hidden max-w-full">{t.seatName}</div>
                                {/*<div className="text-sm text-gray-500">
                                    {file.category} | {file.tags.join(', ')} |{' '}
                                    {(file.size / 1024 / 1024).toFixed(2)}MB
                                </div>*/}
                                {!t.checkedAt && eventData?.eventDate && <div className="text-xs text-gray-400">
                                    День матча: {new Date(eventData.eventDate).toLocaleString()} {/*|{' '}*/}
                                    {/*file.uploadedBy*/}
                                </div>}
                                {t.checkedAt && <div className="text-xs text-gray-400">
                                    Отсканирован: {new Date(t.checkedAt).toLocaleString()} {/*|{' '}*/}
                                    {/*file.uploadedBy*/}
                                </div>}
                            </div>
                            {/*<div className="flex gap-2 min-w-8">
                    <button
                        onClick={() => handlePreview(file.name)}
                        className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition"
                    >
                        <svg className="w-6 h-6 text-inherit" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeWidth="1" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                            <path stroke="currentColor" strokeWidth="1" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>

                    </button>
                    <button
                        onClick={() => handleDelete(file.name)}
                        className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition"
                    >
                        <svg className="w-6 h-6 text-inherit" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                </div>*/}
                        </div>
                    )
                })}
            </div>
        </>
    );
}