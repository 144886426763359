import { Link } from "react-router-dom";
import { SmallCard } from "./components/SmallCard";
import { SmallCardsSection } from "./components/SmallCardsSection";
import { useAppSelector } from "../../store";
import { selectAppConfig } from "../../store/selectors";

const DEFAULT_MERCH = [
    {
        key: "merch1",
        backgroundUrl: "https://optim.tildacdn.com/tild6439-3166-4136-a561-353833633535/-/format/webp/t-iYvPRNFws.jpg",
        title: "",
        linkUrl: "https://shop.fcrodina.com/#!/tproduct/582452896-1694168931949",
    },
    {
        key: "merch2",
        backgroundUrl: "https://optim.tildacdn.com/tild3261-3135-4135-a662-646462663437/-/format/webp/noroot.jpg",
        title: "",
        linkUrl: "https://shop.fcrodina.com/#!/tproduct/582391408-1612791953580",
    },
    {
        key: "merch3",
        backgroundUrl: "https://optim.tildacdn.com/tild3132-3364-4766-a437-326637386136/-/format/webp/__2024-07-15_184804.jpg",
        title: "",
        linkUrl: "https://shop.fcrodina.com/#!/tproduct/582452896-1721053914468",
    },
    {
        key: "merch4",
        backgroundUrl: "https://optim.tildacdn.com/tild6564-3130-4737-a562-303839313138/-/format/webp/__2024-04-24_152311.jpg",
        title: "",
        linkUrl: "https://shop.fcrodina.com/#rec582442313#!/tproduct/582391408-1714722379004",
    },
]

export const MerchList = () => {
    const config = useAppSelector(selectAppConfig);

    const merchList = config?.shopItems || DEFAULT_MERCH;

    return (
        <SmallCardsSection
            title="МЕРЧ"
            actionLink={{
                title: "В фаншоп",
                url: config?.shopUrl || "https://shop.fcrodina.com/#rec582391406",
            }}
        >
            {merchList.map(m => {
                const bgClass = `bg-[url('${m.backgroundUrl}')`;
                return (
                    <SmallCard key={m.key} className={bgClass + " bg-cover bg-center relative overflow-hidden cursor-pointer"}>
                        <Link to={m.linkUrl || ''} className="w-full h-full" >
                            <img src={m.backgroundUrl} className="w-full h-full absolute object-cover" />
                        </Link>
                    </SmallCard>
                )
            })}
        </SmallCardsSection>
    );
}