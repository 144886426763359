import { call, fork, put, takeLeading } from "redux-saga/effects";
import {
    loadTopIndex,
    loadTopIndexFailure,
    loadTopIndexSuccess,
    selectPlayer,
    selectPlayerFailure,
    selectPlayerSuccess,
} from "./reducer";
import { LoadTopIndexResponse, SelectPlayerPayload, SelectPlayerResponse } from "./types";
import * as topIndexTransport from "../../../api/transport/contests/topIndexTransport";
import { PayloadAction } from "@reduxjs/toolkit";

function* selectedPlayerWatcher() {
    yield takeLeading(selectPlayer.type, selectPlayerAsync);
}

function* selectPlayerAsync({ payload }: PayloadAction<SelectPlayerPayload>) {
    try {
        const responseData: SelectPlayerResponse = yield call(topIndexTransport.selectPlayerId, payload.playerId, payload.userUuid, payload.matchId);

        yield put(selectPlayerSuccess({
            playerId: responseData.selectedPlayerId,
        }));
    } catch (error: any) {
        yield put(selectPlayerFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* loadTopIndexWatcher() {
    yield takeLeading(loadTopIndex.type, loadTopIndexAsync);
}

function* loadTopIndexAsync({ payload }: PayloadAction<{
    userUuid: string,
}>) {
    try {
        const responseData: LoadTopIndexResponse = yield call(topIndexTransport.loadTopIndexInfo, payload.userUuid);

        yield put(loadTopIndexSuccess(responseData));
    } catch (error: any) {
        yield put(loadTopIndexFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

export function* topIndexSaga() {
    yield fork(selectedPlayerWatcher);
    yield fork(loadTopIndexWatcher);
}
