import { Link, useNavigate } from "react-router-dom";
import { InternalPage } from "../../components/layout/InternalPage"
import { useAppDispatch, useAppSelector } from "../../store";
import { selectAppConfig, selectAskCoach, selectUserProfile } from "../../store/selectors";
import { RoundButton as Button } from "../../components/Button";
import { Textarea } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { RulesPopup } from "./Rules";
import { AskCoachStatus } from "../../store/slices/ask-coach/types";
import { loadAskCoach, sendAskCoachRequest } from "../../store/slices/ask-coach/reducer";
import { getEventInfo } from "../../utils/tickets";
import { useNavigateBack } from "../../hooks";

export const AskCoachPage = () => {
    const navigate = useNavigate();
    const navigateBack = useNavigateBack();
    const profile = useAppSelector(selectUserProfile);
    const config = useAppSelector(selectAppConfig);
    const askCoach = useAppSelector(selectAskCoach);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadAskCoach({}));
    }, [dispatch, profile?.id]);

    const [showRules, setShowRules] = useState(false);
    const [text, setText] = useState('');

    const hasTicket = profile?.tickets?.find(t => getEventInfo(t.eventId)?.eventId === config?.eventsConfig?.prev?.toString());
    const isStub = askCoach.status === AskCoachStatus.CLOSED || askCoach.hasRequest;

    const onSendRequest = useCallback(() => {
        dispatch(sendAskCoachRequest({
            text,
        }))
    }, [dispatch, text]);

    const lastMatch = getEventInfo(config?.eventsConfig?.prev?.toString() || '-');

    return (
        <InternalPage onBack={() => navigate('/')} title="Вопрос Зорану Зельковичу">
            {isStub && <div className="p-2 pb-20 flex flex-col gap-2 items-center justify-center bg-slate-950 min-h-screen text-white text-2xl font-semibold text-center">
                {askCoach.hasRequest && (
                    <>
                        <div>
                            Твой вопрос на <br /><span className="text-blue-300 cursor-pointer inline" onClick={() => setShowRules(true)}>модерации</span>
                        </div>
                    </>
                )}
                {!askCoach.hasRequest && <>Приём вопросов окончен</>}
            </div>}
            {!isStub && <div className="p-2 pb-20 flex flex-col gap-2 texl-lg">
                <div className="text-justify text-lg p-2">
                    Придумай интересный вопрос, соответствующий <span className="text-blue-500 cursor-pointer inline" onClick={() => setShowRules(true)}>правилам</span>.<br />
                    Лучший, по мнению нашей пресс-службы, будет задан на послематчевой пресс-конференции. Ответ Зорана Зельковича на твой вопрос опубликуем в программке к следующему матчу.
                </div>
                <Textarea
                    id="comment"
                    placeholder="Текст вопроса здесь..."
                    required
                    rows={4}
                    className="text-2xl px-3 py-2"
                    onChange={(e) => setText(e.currentTarget.value)}
                />
                <Button disabled={!profile?.confirmed || !hasTicket} onClick={() => onSendRequest()}>
                    Отправить
                </Button>
                {!hasTicket && !!profile?.confirmed && <div className="text-center">Доступно болельщикам посетившим матч {lastMatch?.eventName || ''}</div>}
                {!profile?.confirmed && <div className="text-center">Доступно болельщикам с <Link to="/auth/login?next=/ask-coach" replace className="text-blue-500">подтвежденным</Link> e-mail, посетившим матч {lastMatch?.eventName || ''}</div>}
            </div>}
            <RulesPopup show={showRules} onClose={() => setShowRules(false)} />
        </InternalPage>
    );
}