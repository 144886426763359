import { Link } from "react-router-dom";
import { useAppSelector } from "../store"
import { selectAuth } from "../store/selectors"
import { Avatar } from "flowbite-react";
import { getInitials } from "../utils";
import { UserProfileIcon } from "../components/Icons";

export const UserAccountIcon = () => {
    const auth = useAppSelector(selectAuth);

    let content = <UserProfileIcon className="border-2 border-solid border-white rounded-full"/>;

    if (auth.authorized) {
        if (auth.userProfile?.name) {
            content = <Avatar rounded placeholderInitials={getInitials(auth.userProfile.name)} className="border-2 border-solid border-white rounded-full w-10 h-10"/>
        } else {
            content = <Avatar rounded className="border-2 border-solid border-white rounded-full"/>
        }
    }

    return (
        <Link to="/profile">
            {content}
        </Link>
    )
}