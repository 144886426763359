import { useState } from "react";
import { requestNotificationPermission } from "../utils/requestPermissions";
import { isIOS, isPWAInstalled } from "../utils";

export const RequestNotificationBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    if (isIOS() && !isPWAInstalled()) {
        return null;
    }

    if (Notification?.permission !== 'default') {
        return null;
    }

    if (!isBannerVisible) {
        return null;
    }

    const onClick = () => {
        requestNotificationPermission();
        setIsBannerVisible(false);
    }

    return (
        <div className="w-full mx-auto sm:rounded-xl sm:max-w-96 p-2 px-3 bg-sky-900 flex flex-row gap-2 justify-center items-center" onClick={onClick}>
            <div className='w-full flex flex-row items-center'>
                <div className='text-white p-2 font-bold'>
                    🔔 Включи уведомления, чтобы не пропустить самого важного
                </div>
                <button className="h-8 bg-white opacity-95 text-center rounded-full text-sm uppercase px-3 font-bold" onClick={onClick}>
                    Разрешить
                </button>
            </div>
        </div>
    );
}