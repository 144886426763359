import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayerInfo, SelectPlayerPayload, SelectPlayerSuccessPayload, TopIndexStatus, TopIndexSuccessPayload } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface TopIndexState {
    status: TopIndexStatus,
    selectedPlayerId: string,
    players?: PlayerInfo[],
    topScorePlayer?: PlayerInfo,
    loading?: boolean,
    error?: string,
    updateStatusTime: number,
    matchId?: number,
    matchName?: string,
}

// Define the initial state using that type
const initialState: TopIndexState = {
    selectedPlayerId: '',
    players: [],
    status: TopIndexStatus.Wait,
    updateStatusTime: 0,
    matchName: 'Родина - Тюмень',
};

const topIndex = createSlice({
    name: 'topIndex',
    initialState,
    reducers: {
        selectPlayer: (state, actions: PayloadAction<SelectPlayerPayload>) => {
            state.loading = true;
            state.error = '';
        },
        selectPlayerSuccess: (state, actions: PayloadAction<SelectPlayerSuccessPayload>) => {
            state.loading = true;
            state.error = '';
            state.selectedPlayerId = actions.payload.playerId;
        },
        selectPlayerFailure: (state, actions: PayloadAction<{}>) => {
            state.loading = true;
            state.error = 'Не удалось выбрать игрока';
        },
        loadTopIndex: (state, actions: PayloadAction<{}>) => {
            state.loading = true;
            state.error = '';
        },
        loadTopIndexSuccess(state, actions: PayloadAction<TopIndexSuccessPayload>) {
            state.loading = false;
            state.error = '';
            state.status = actions.payload.status;
            state.players = actions.payload.players;
            if (state.status === TopIndexStatus.Selection && !state.players.length) {
                state.status = TopIndexStatus.Wait;
            }
            state.topScorePlayer = actions.payload.topScorePlayer;
            state.selectedPlayerId = actions.payload.selectedPlayerId || '';
            state.matchId = actions.payload.matchId;
            state.matchName = actions.payload.matchName;
        },
        loadTopIndexFailure(state, actions: PayloadAction<{}>) {
            state.loading = false;
            state.error = 'Не удалось загрузить информацию';
        }
    }
})

export const {
    selectPlayer,
    selectPlayerSuccess,
    selectPlayerFailure,
    loadTopIndex,
    loadTopIndexFailure,
    loadTopIndexSuccess,
} = topIndex.actions;

export default topIndex.reducer;