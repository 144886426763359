import { useAppSelector } from "../../store";
import { selectMatchPlayerVote } from "../../store/selectors";
import { VoteStatus } from "../../store/slices/matchPlayerVote/types";

export const MatchBanner = () => {
    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);

    return (
        <div className="relative font-bold text-white">
            {matchPlayerVote.status === VoteStatus.COMPLETE && <div className="text-2xl absolute top-1 left-0 right-0 text-center flex flex-row justify-center opacity-85">
                МАТЧ ОКОНЧЕН
            </div>}
            <div className="text-5xl absolute bottom-0 left-0 right-0 text-center flex flex-row justify-center gap-[68px] opacity-90">
                {matchPlayerVote.fixtureData?.score?.split(":").map((score, index) => (
                    <span key={index} className="px-2 py-1 mx-1">{score}</span>
                ))}
            </div>
            <img src={matchPlayerVote?.bannerUrl} alt="ФК Родина" className="w-full h-48 object-cover object-left rounded-3xl" />
        </div>
    );
}
