import { InternalPage } from "../../components/layout/InternalPage"
import { useNavigateBack } from "../../hooks";

export const GuessScoreContestPage = () => {
    const navigate = useNavigateBack('/contests');
    return (
        <InternalPage
            onBack={() => navigate()}
            title="УГАДАЙ СЧЁТ"
        >
            <div>
                <h1 className="text-xl">Угадай счёт</h1>
            </div>
        </InternalPage>
    )
}