import { FC } from "react";
import { RustatIcon } from "./components/RustatIcon";

interface PlayerCardProps {
    name: string;
    photoUrl: string;
    index: number;
    onSelect?: () => void;
    selected?: boolean;
    position?: string;
}

export const PlayerCard: FC<PlayerCardProps> = ({
    name,
    photoUrl,
    index,
    onSelect,
    selected,
    position,
}) => {
    let containerClasses = "shadow-lg shadow-sky-700 rounded relative min-h-48 cursor-pointer";

    if (selected) {
        containerClasses += " bg-sky-500/50 shadow-xl shadow-sky-300"
    }

    let pos = '';

    if (position) {
        if (position === 'Вратарь') {
            pos = 'ВР';
        } else if (position === 'Защитник') {
            pos = 'ЗЩ';
        } else if (position === 'Полузащитник') {
            pos = 'ПЗЩ';
        } else if (position === 'Нападающий') {
            pos = 'НАП';
        }
    }

    return (
        <div className={containerClasses} onClick={onSelect}>
            <div className="flex flex-row justify-between items-start">
                <div className="p-2 flex flex-row items-center gap-1"><RustatIcon className="w-5 h-5" />{index}</div>
                <div className="p-2">
                    {pos}
                </div>
            </div>
            <img src={photoUrl} className="w-full object-contain object-bottom" alt={name} />
            <div className="absolute bottom-0 left-0 right-0 bg-sky-950/60 to-sky-950 text-center p-1">
                <div className="text-lg font-medium">
                    {name}
                </div>
            </div>
        </div>
    )
}