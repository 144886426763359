import { refreshAccessToken } from './transport/authTransport';

let accessToken = '';

export const setAccessToken = (token: string) => {
    accessToken = token;
};

export const fetchSecured = async (url: string, options: RequestInit = {}): Promise<any> => {
    const headers: HeadersInit = {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    };

    let response = await fetch(`${url}`, {
        ...options,
        headers,
        credentials: 'include',
    });

    console.info(response.status, response.statusText);

    if (response.status === 401) {
        try {
            const newAccessToken = await refreshAccessToken();
            setAccessToken(newAccessToken);

            response = await fetch(`${url}`, {
                ...options,
                headers: {
                    ...headers,
                    Authorization: `Bearer ${newAccessToken}`,
                },
                credentials: 'include',
            });
        } catch (err) {
            console.error('Refresh failed. Redirect to login?', err);
            throw err;
        }
    }

    if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    return response.json();
};
