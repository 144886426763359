import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
    title: string;
    onClick?: () => void;
    href?: string;
    type?: "primary" | "secondary" | "link" | "danger";
    disabled?: boolean;
    className?: string;
}

export const Button: FC<ButtonProps> = ({
    title,
    onClick,
    href,
    type = "primary",
    disabled,
    className = '',
}) => {
    let classNames = "text-white text-md p-3 rounded transition-colors duration-300";

    if (type === "primary") {
        classNames += " bg-sky-950 hover:bg-sky-900";
    } else if (type === "secondary") {
        classNames += " bg-gray-500 hover:bg-gray-600";
    } else if (type === "danger") {
        classNames += " bg-red-500 hover:bg-red-600";
    } else if (type === "link") {
        classNames += " text-blue-500 hover:underline";
    }

    if (disabled) {
        classNames += " opacity-50 cursor-not-allowed pointer-events-none";
    }

    if (className) {
        classNames += ` ${className}`;
    }

    if (href) {
        return (
            <a href={href} className={classNames}>{title}</a>
        );
    }

    return (
        <button className={classNames} onClick={onClick}>{title}</button>
    );
}

interface RoundButtonProps {
    children: ReactNode;
    onClick?: () => void;
    href?: string;
    size?: "base" | "xs" | "xl";
    variant?: "white" | "blue";
    disabled?: boolean;
}

export const RoundButton: FC<RoundButtonProps> = ({
    children,
    onClick,
    href,
    size = "base",
    disabled,
}) => {
    let classNames = "transition-all text-center font-bold uppercase italic active:scale-95 bg-slate-950 text-white rounded-full border-2 border-solid border-white shadow-sm text-center";

    switch (size) {
        case "xs":
            classNames += " text-sm px-1 py-1 w-10 h-10";
            break;
        case "xl":
            classNames += " text-xl px-3 py-3 min-w-16";
            break;
        case "base":
        default:
            classNames += " px-3 py-3 min-w-12";
    }

    if (disabled) {
        classNames += ' opacity-75 pointer-events-none cursor-wait'
    } else {
        classNames += ' cursor-pointer';
    }

    if (href) {
        return (
            <Link
                to={href}
                className={classNames}
            >
                {children}
            </Link>
        );
    }

    return (
        <div
            onClick={onClick}
            className={classNames}
        >
            {children}
        </div>
    );
}