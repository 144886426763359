import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfig } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface ConfigState {
    app?: AppConfig;
    loading?: boolean;
}

// Define the initial state using that type
const initialState: ConfigState = {
    loading: false,
}

const config = createSlice({
    name: 'config',
    initialState,
    reducers: {
        loadConfig: (state, action: PayloadAction<{}>) => {
            state.loading = true;
        },
        loadConfigSuccess: (state, action: PayloadAction<{ appConfig: AppConfig }>) => {
            state.app = {...action.payload.appConfig};
            state.loading = false;
        },
        loadConfigFailure: (state, action: PayloadAction<{}>) => {
            state.loading = false;
        },
    }
})

export const {
    loadConfig,
    loadConfigSuccess,
    loadConfigFailure,
} = config.actions;

export default config.reducer;