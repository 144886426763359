import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AskCoachConfig, AskCoachStatus, AskCoachSuccessPayload, SendAskCoachRequestPayload } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface AskCoachState extends AskCoachConfig {
    loading?: boolean,
    error?: string,
}

// Define the initial state using that type
const initialState: AskCoachState = {
    status: AskCoachStatus.CLOSED,
    hasRequest: false,
};

const askCoach = createSlice({
    name: 'askCoach',
    initialState,
    reducers: {
        loadAskCoach: (state, action: PayloadAction<{}>) => {
            state.loading = true;
            state.error = '';
        },
        loadAskCoachSuccess(state, action: PayloadAction<AskCoachSuccessPayload>) {
            state.loading = false;
            state.error = '';
            state.status = action.payload.status;
            state.hasRequest = !!action.payload.hasRequest;
        },
        loadAskCoachFailure(state, actions: PayloadAction<{}>) {
            state.loading = false;
            state.error = 'Не удалось загрузить информацию';
        },
        sendAskCoachRequest(state, action: PayloadAction<SendAskCoachRequestPayload>) {
            state.loading = true;
        },
        sendAskCoachRequestSuccess(state, action: PayloadAction<AskCoachSuccessPayload>) {
            state.loading = false;
            state.status = action.payload.status;
            state.hasRequest = !!action.payload.hasRequest;
        },
        sendAskCoachRequestFailure(state, action: PayloadAction<{}>) {
            state.loading = false;
        }
    }
})

export const {
    loadAskCoach,
    loadAskCoachFailure,
    loadAskCoachSuccess,
    sendAskCoachRequest,
    sendAskCoachRequestSuccess,
    sendAskCoachRequestFailure,
} = askCoach.actions;

export default askCoach.reducer;