import { UserProfile } from "../../store/slices/auth/types";
import { fetchSecured } from "../fetchSecured";
import { API_GW_URL } from "./consts";

const TRANSPORT_BASE_URL = `${API_GW_URL}/auth`;

export const getProfile = async () => {
    //const res = await fetch(`${TRANSPORT_BASE_URL}/profile`, { method: "GET" });
    let userData = null;

    try {
        const res = await fetchSecured(`${TRANSPORT_BASE_URL}/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        userData = {
            ...res,
        }
    } catch (e) {
        console.log('Unable to retrieve user data', e)
        throw Error('User profile not loaded');
    }

    return Promise.resolve({ user: userData });
}

export const refreshTickets = async () => {
    let res;

    try {
        res = await fetchSecured(`${TRANSPORT_BASE_URL}/refresh-tickets`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (e) {
        console.log('Unable to retrieve user data', e)
        throw Error('Tickets not loaded');
    }

    return Promise.resolve({ tickets: res });
}

export const login = async (username: string, password: string) => {
    const res = await fetch(`${TRANSPORT_BASE_URL}/login`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include',
    });

    if (!res.ok) {
        throw new Error('Ошибка при входе');
    }

    const { accessToken } = await res.json();
    return accessToken;
}

export const refreshAccessToken = async (): Promise<string> => {
    const res = await fetch(`${TRANSPORT_BASE_URL}/refresh`, {
        method: 'POST',
        credentials: 'include', // отправляем refreshToken из cookie
    });

    if (!res.ok) throw new Error('Refresh token expired');

    const { accessToken } = await res.json();
    return accessToken;
};

export const logout = async () => {
    return fetch(`${TRANSPORT_BASE_URL}/logout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include',
    });
}

export const register = async (userData: { email: string, password: string, name: string }) => {
    const res = await fetch(`${TRANSPORT_BASE_URL}/register`, {
        method: "POST",
        body: JSON.stringify({ ...userData }),
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!res.ok) {
        throw new Error('Ошибка при загрузке');
    }

    return res.json();
}

export const confirmEmailAddress = async (email: string, token: string) => {
    const res = await fetch(`${TRANSPORT_BASE_URL}/confirm-email`, {
        method: "POST",
        body: JSON.stringify({
            email,
            token,
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!res.ok) {
        throw new Error('Учётная запись не подтверждена');
    }

    return res.text();
}