import { useLocation, useParams } from "react-router-dom";
import { InternalPage } from "../../components/layout/InternalPage";
import { useAppDispatch, useAppSelector } from "../../store";
import { Landing } from "./Landing";
import { useEffect, useState } from "react";
import { MainPage } from "./MainPage";
import { TopIndexSelectionPage } from "./SelectionPage";
import { loadTopIndex } from "../../store/slices/top-index/reducer";
import { selectAuth } from "../../store/selectors";
import { useNavigateBack } from "../../hooks";

export const TopIndexPage = () => {
    const [showLanding, setShowLandingLanding] = useState(false);
    const navigateBack = useNavigateBack('/contests');
    const dispatch = useAppDispatch();
    const { page } = useParams<{ page?: string }>();

    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const dev = search.get('dev');
    const auth = useAppSelector(selectAuth);

    useEffect(() => {
        if (dev) {
            setShowLandingLanding(false);
        }
        dispatch(loadTopIndex({
            userUuid: auth.uuid,
        }));
    }, [auth.uuid, dev, dispatch]);

    let pageContent = <MainPage />;

    if (page === 'selection') {
        pageContent = <TopIndexSelectionPage />;
    }

    const handleBackClick = () => {
        navigateBack();
    };


    return (
        <InternalPage
            title="ТОП-ИНДЕКС"
            onBack={handleBackClick}
        >
            <div className="bg-slate-900 h-screen w-full">
                {showLanding && <Landing />}
                {!showLanding && pageContent}
            </div>
        </InternalPage>
    )
}