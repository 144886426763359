import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

export interface RegistrationFormValues {
    name: string;
    email: string;
    agree: string;
    password: string;
    passwordRepeat: string;
}

type RegistrationFormErrors = Record<keyof RegistrationFormValues, string | undefined>;

interface RegistrationFormProps {
    onFormFinished?: (values: RegistrationFormValues) => void;
}

export const RegistrationForm:FC<RegistrationFormProps> = ({
    onFormFinished,
}) => {
    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        agree: "",
        password: "",
        passwordRepeat: ""
    });

    const [formErrors, setFormErrors] = useState<RegistrationFormErrors>({
        name: undefined,
        email: undefined,
        agree: undefined,
        password: undefined,
        passwordRepeat: undefined,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(e.target.id, e.target.value)
        setFormValues({ ...formValues, [e.target.name]: e.target.value });

        if (e.target.name === "password" || e.target.name === "passwordRepeat") {
            if (e.target.value !== formValues.password) {
                setFormErrors({ ...formErrors, password: "Пароли не совпадают", passwordRepeat: "Пароли не совпадают" });
            } else {
                setFormErrors({ ...formErrors, password: undefined, passwordRepeat: undefined });
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (Object.values(formErrors).some((v) => !!v)) {
            console.error('Has errors', formErrors);
            return;
        }

        onFormFinished!(formValues);
    }

    return (
        <form className="flex max-w-md flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Твоё имя" />
                </div>
                <TextInput id="name" name="name" type="text" placeholder="Имя" shadow onChange={handleChange}/>
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email2" value="Email для входа" />
                </div>
                <TextInput id="email2" name="email" type="email" placeholder="E-mail для входа" required shadow onChange={handleChange}/>
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password" value="Пароль" />
                </div>
                <TextInput
                    id="password"
                    name="password"
                    type="password"
                    required
                    shadow
                    onChange={handleChange}
                    color={formErrors.passwordRepeat ? "failure" : "gray"}
                    helperText={formErrors.passwordRepeat}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="passwordRepeat" value="Повтори пароль" />
                </div>
                <TextInput
                    id="passwordRepeat"
                    name="passwordRepeat"
                    type="password"
                    required
                    shadow
                    onChange={handleChange}
                    color={formErrors.passwordRepeat ? "failure" : "gray"}
                    helperText={formErrors.passwordRepeat}
                />
            </div>
            <div className="flex items-start gap-2 w-full">
                <Checkbox id="agree" name="agree" onChange={handleChange}/>
                <Label htmlFor="agree" className="w-full">
                    <div>
                        Я согласен на обработку данных в соответствии с &nbsp;
                        <Link to="https://fcrodina.com/" className="text-cyan-600 hover:underline dark:text-cyan-500">
                            политикой конфиденциальности
                        </Link>
                    </div>
                </Label>
            </div>
            <Button type="submit" className="bg-sky-950 hover:bg-sky-900 focus:bg-sky-950 active:bg-sky-800">Зарегистрироваться</Button>
        </form>
    );
}
