import React, { FC } from "react";
import { Link } from "react-router-dom";

interface ContestCardProps {
    name: string;
    url: string;
    children?: React.ReactNode;
    className?: string;
}

export const ContestCard: FC<ContestCardProps> = ({
    name,
    children,
    url,
    className,
}) => {
    let classNames = "flex items-center justify-center text-white w-full max-w-96 aspect-video rounded-2xl bg-sky-500";

    if (className) {
        classNames += ' ' + className;
    }

    return (
        <Link to={url} className={classNames}>
            {name}
            {children}
        </Link>
    )
}