import { useState, useEffect } from 'react';
import MobileLayout from './layout/MobileLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MatchMVPVotePage } from './pages/mvp-vote';
import { SplashScreen } from './components/SplashScreen';
import { RequestNotificationBanner } from './components/RequestNotifications';
import { InstallBanner } from './components/InstallBanner';
import { TicketsPage } from './pages/TicketsPage';
import { TopIndexPage } from './pages/top-index';
import { AuthPages } from './pages/auth';
import { Flowbite } from 'flowbite-react';
import { customTheme } from './components/theme';
import { ProfilePage } from './pages/profile';
import { useAppDispatch } from './store';
import { loadUserProfile } from './store/slices/auth/reducer';
import { HomePage } from './pages/home';
import { ContestsPage } from './pages/contests';
import { GuessScoreContestPage } from './pages/guess-score';
import { ConfirmEmailPage } from './pages/auth/confirm-email';
import { TicketsWalletPage } from './pages/profile/tickets';
import { loadConfig } from './store/slices/config/reducer';
import { AskCoachPage } from './pages/ask-coach';
import { CheckInPage } from './pages/check-in';
import { loadMatchPlayerVote } from './store/slices/matchPlayerVote/reducer';

const App = () => {
    const [loading, setLoading] = useState(true);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [isServiceUrl, setIsServiceUrl] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        /*const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('access_token')) {
            const accessToken = searchParams.get('access_token');
            localStorage.setItem('access_token', accessToken as string);
            window.location.search = '';
        }*/

        //if (isMobile()) {

        //console.log(window.location.pathname);

        if (window.location.pathname.startsWith('/confirm-email')) {
            setIsServiceUrl(true);
            return;
        }

        dispatch(loadUserProfile({}));
        dispatch(loadConfig({}));
        dispatch(loadMatchPlayerVote({}));
        setTimeout(() => {
            setShowSplashScreen(false);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }, 3000);
        //}
    }, []);


    if (isServiceUrl) {
        return (
            <ConfirmEmailPage />
        )
    }

    const top = (
        <>
            <InstallBanner />
            <RequestNotificationBanner />
        </>
    );

    return (
        <>
            {<>
                <Flowbite theme={{ theme: customTheme }}>
                    <MobileLayout top={top}>
                        <Routes>
                            <Route path="/vote" element={<MatchMVPVotePage />} >
                                <Route path=":page" element={<MatchMVPVotePage />} />
                            </Route>
                            <Route path="/tickets" element={<TicketsPage />} />
                            <Route path="/top-index/:page?" element={<TopIndexPage />} />
                            <Route path="/auth/:page" element={<AuthPages />} />
                            <Route path="/profile/tickets" element={<TicketsWalletPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/guess-score" element={<GuessScoreContestPage />} />
                            <Route path="/contests" element={<ContestsPage />} />
                            <Route path="/ask-coach" element={<AskCoachPage />} />
                            <Route path="/check-in" element={<CheckInPage />} />
                            <Route path="/" element={<HomePage />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </MobileLayout>
                </Flowbite>
            </>}
            {loading && <SplashScreen fade={!showSplashScreen} />}
        </>
    );
};

export default App;
