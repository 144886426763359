import { PageContainer } from "../../components/layout";
import { RustatIcon } from "../top-index/components/RustatIcon";
import { ContestCard } from "./ContestCard";

export const ContestsPage = () => {
    return (
        <PageContainer>
            <h1 className="text-2xl uppercase font-bold self-start">Конкурсы для тебя</h1>
            <ContestCard name="" url="/top-index">
                <div className="p-2 flex w-full bg-slate-900 h-full flex-col justify-between items-center gap-2 rounded-2xl text-white font-bold">
                    <span className="text-2xl">ТОП-ИНДЕКС</span>
                    <RustatIcon className="w-24 h-24" />
                    <span>Ставь на лучшего по статистике</span>
                </div>
            </ContestCard>
            <ContestCard name="" url="/vote" className="!bg-[url(https://fcrodina.com/news/73eb8f6204c2d7485329ea646548f9ad.jpg)] bg-cover bg-center overflow-hidden">
                <div className="pt-2 flex w-full h-full flex-col justify-between items-center gap-2 rounded-2xl text-white font-bold bg-sky-900/30">
                    <span className="text-2xl [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">ЛУЧШИЙ ИГРОК</span>
                    <span className="py-1 w-full bg-sky-950/25 rounded px-2 text-xl text-center [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">Выбери игрока и <br />вручи ему приз лично</span>
                </div>
            </ContestCard>
            {/*<ContestCard name="" url="/guess-score" className="!bg-[url(https://fcrodina.com/news/fb2eee3e3c996fad693a75bc85725b8c.jpg)] bg-cover bg-center overflow-hidden">
                <div className="pt-2 flex w-full h-full flex-col justify-between items-center gap-2 rounded-2xl text-white font-bold bg-sky-900/30">
                    <span className="text-2xl [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">Конкурс прогнозов</span>
                    <span className="py-1 w-full bg-sky-950/25 px-2 text-center [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">Приходи на стадион, угадай счёт  <br /> в матче и получи призы</span>
                </div>
            </ContestCard>*/}
        </PageContainer>
    )
}