import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface BottomMenuItemProps {
    title: string;
    icon: React.ReactNode;
    href: string;
    target?: string;
}

export const BottomMenuItem: FC<BottomMenuItemProps> = ({ title, href, icon, target = '_self' }) => {
    return (
        <Link to={href} target={target} className="flex flex-col items-center h-12 w-12">
            <span>{icon}</span>
            <span className="text-xs">{title}</span>
        </Link>
    );
}