export enum VoteStatus {
    CREATED = 'created',
    STARTED = 'started',
    WAITING_RESULTS = 'waitingResults',
    COMPLETE = 'complete',
    CLOSED = 'closed',
    CANCELLED = 'cancelled',
}

export enum TicketCheckResult {
    OK = 'ok',
    ERROR = 'error',
    UNKNOWN = 'unknown',
};

export interface MatchPlayerVoteDto {
    uuid: string;
    eventId: number;
    status: VoteStatus;
    matchName: string;
    startDate?: number;
    endDate?: number;
    candidates: string[];
    selectedPlayer?: string;
    selectedTicket?: string;
    result?: {
        winner: string;
        ticketId: string;
    };
    bannerUrl?: string;
    matchId: number;
}

export interface MatchPlayerVoteResponse extends MatchPlayerVoteDto {
}

export interface MatchPlayerVote {
    matchName: string;
    startDate: number;
    endDate: number;
    candidates: string[];
    status: VoteStatus;
    result?: {
        winner: string;
        ticketId: string;
    };
    bannerUrl?: string;
    matchId: number;
}

export interface FixtureShortInfoDto {
    id: number;
    score: string;
}

export interface MatchCheckInPayload {
    matchId: string;
}

export interface MatchCheckInSuccessPayload {
    checkInId: string;
}