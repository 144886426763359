export interface SelectPlayerPayload {
    playerId: string;
    userUuid: string;
    matchId?: number;
}

export interface SelectPlayerResponse {
    selectedPlayerId: string;
}

export interface SelectPlayerSuccessPayload {
    playerId: string;
}

export interface PlayerInfo {
    id: string;
    name: string;
    photoUrl: string;
    indexScoreAverage: number;
    indexScoreLast: number;
    position: string;
}

export interface TopIndexInfo {
    status: TopIndexStatus;
    selectedPlayerId?: string;
    players: PlayerInfo[];
    topScorePlayer?: PlayerInfo;
    updateStatusTime?: number;
    matchId?: number;
    matchName?: string;
}

export interface TopIndexSuccessPayload extends TopIndexInfo {}

export interface LoadTopIndexResponse extends TopIndexInfo {}

export enum TopIndexStatus {
    Inactive,
    Wait,
    Selection,
    Scoring,
    Results,
}
