import { FC } from 'react';
import icon from '../../../assets/rustat_white.svg';

interface RustatIconProps {
    className?: string;
}

export const RustatIcon: FC<RustatIconProps> = ({
    className,
}) => {
    return <img src={icon} className={className} />
}
