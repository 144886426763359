import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store"
import { selectUserProfile } from "../store/selectors";
import { loadUserProfile } from "../store/slices/auth/reducer";

export const useUserProfile = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadUserProfile({}));
    }, [dispatch]);

    const userProfile = useAppSelector(selectUserProfile);
    if (!userProfile) {
        return null;
    }

    return {
        ...userProfile,
    }
}