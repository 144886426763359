import React, { FC } from 'react';
import { BottomMenu } from '../components/BottomMenu';
//import logo from '../logo.svg';
import { ContestsIcon, HomeIcon } from '../components/Icons';
import { useLocation, useParams } from 'react-router-dom';
import { UserAccountIcon } from '../widgets/UserAccountIcon';

interface MobileLayoutProps {
    children: React.ReactNode;
    top?: React.ReactNode;
}

const MobileLayout: FC<MobileLayoutProps> = ({
    children,
    top,
}) => {
    ///const params = useParams();
    const location = useLocation();

    //const showHeader = false && (location.pathname === '/' || location.pathname.startsWith('/vote'));
    let containerClasses = "min-h-screen min-w-80 relative bg-slate-900";

    /*if (location.pathname.startsWith('/top-index')) {
        containerClasses += " bg-slate-900";
    } else if (location.pathname.startsWith('/auth')) {
        containerClasses += " dark:bg-sky-950";
    } else {
        containerClasses += " bg-slate-100";
    }*/

    return (
        <div className={containerClasses} style={{fontFamily: "Futura"}}>
            {/* Header */}
            {top}
            {/*showHeader && (
                <>
                    <header className="sticky top-0 left-0 right-0 bg-sky-950 text-white px-4 py-2 flex flex-row justify-between items-center z-10">
                        <div className='flex flex-row gap-3 justify-start items-center'>
                            <img src={logo} alt="ФК Родина" className="w-12 h-12" />
                            <div>
                                <h1 className="text-xl font-semibold">РОДНЫЕ</h1>
                                <h3 className="text-sm font-light">Клуб болельщиков ФК «Родина»</h3>
                            </div>
                        </div>
                        <div>
                            <UserAccountIcon />
                        </div>
                    </header>
                </>
            )*/}

            {/* Main Content */}
            <main className="relative space-y-6 flex flex-col justify-start items-center w-full min-h-screen bg-slate-100 dark:bg-sky-950 dark:text-white mx-auto sm:max-w-96 overflow-x-hidden">
                {children}
            </main>

            {/* Bottom Navigation */}
            <BottomMenu items={[
                { title: 'Главная', href: '/', icon: <HomeIcon /> },
                { title: 'Конкурсы', href: '/contests', icon: <ContestsIcon /> },
                { title: 'Профиль', href: '/profile', icon: <UserAccountIcon /> },
            ]} />
        </div>
    );
};

export default MobileLayout;
