import { Link } from "react-router-dom";

export const ActualMatch = () => {
    const isMatchDay = false;
    return (
        <div className="px-3 w-full aspect-video bg-slate-950 rounded-3xl bg-[url(https://fcrodina.com/uploads/my-app/75ea1ad18c6c62db5d70bc0047a8e81d.png)] bg-cover bg-center flex flex-col justify-between items-center">
            {!isMatchDay && (
                <>
                    <h2 className="text-white uppercase font-bold text-center w-full pt-2 text-xl">
                        6 апреля в 19:30
                    </h2>
                    <div className="pb-4">
                        <Link to="https://fcrodina.com/ticket-app/" className="rounded-full bg-white text-sky-950 font-bold text-lg uppercase px-3 py-1">
                            купи билет
                        </Link>
                    </div>
                </>
            )}
            {isMatchDay && (
                <>
                    <h2 className="text-white uppercase font-bold text-center w-full pt-2">
                        ДЕНЬ МАТЧА
                    </h2>
                    <div className="pb-4">

                    </div>
                </>
            )}
        </div>
    )
}