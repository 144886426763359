import { Link } from "react-router-dom";
import { RustatIcon } from "../top-index/components/RustatIcon";
import { SmallCard } from "./components/SmallCard";
import { SmallCardsSection } from "./components/SmallCardsSection";

export const ContestList = () => {
    return (
        <SmallCardsSection
            title="Конкурсы"
            actionLink={{
                title: "Все",
                url: "/contests",
            }}
        >
            <SmallCard className="!bg-slate-900">
                <Link to="/top-index" className="p-2 flex w-full flex-col justify-center items-center gap-2 rounded-2xl text-white font-bold">
                    <RustatIcon className="w-9 h-9" />
                    <span>ТОП-ИНДЕКС</span>
                </Link>
            </SmallCard>
            <SmallCard className="bg-[url(https://fcrodina.com/news/73eb8f6204c2d7485329ea646548f9ad.jpg)] bg-cover bg-center">
                <Link to="/vote" className="p-2 text-center flex w-full h-full flex-col justify-center items-center gap-2 rounded-2xl text-white font-bold bg-sky-950/30">
                    <span className="[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">ЛУЧШИЙ ИГРОК</span>
                </Link>
            </SmallCard>
            {/*<SmallCard className="bg-[url(https://fcrodina.com/news/fb2eee3e3c996fad693a75bc85725b8c.jpg)] bg-cover bg-center">
                <Link to="/guess-score" className="p-2 text-sm flex w-full h-full flex-col justify-center items-center gap-2 rounded-2xl text-white font-bold bg-sky-950/30">
                    <span className="text-sm text-center [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">КОНКУРС ПРОГНОЗОВ</span>
                </Link>
            </SmallCard>*/}
        </SmallCardsSection>
    );
}