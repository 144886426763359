import { fetchSecured } from "../fetchSecured";
import { BACKEND_URL } from "./consts";

const TRANSPORT_BASE_URL = `${BACKEND_URL}/my-app`;

export const checkIn = async (matchId?: string) => {
    const response = await fetch(`${TRANSPORT_BASE_URL}/check-in`, {
        method: "POST",
        body: JSON.stringify({
            matchId,
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });

    const data = await response.json();

    return data;
}
