import { BACKEND_URL } from "../consts";

const TRANSPORT_BASE_URL = `${BACKEND_URL}/my-app/top-index/p`;

export const selectPlayerId = async (playerId: string, userId: string, matchId?: number) => {
    const response = await fetch(`${TRANSPORT_BASE_URL}/select-player`, {
        method: "POST",
        body: JSON.stringify({
            playerId,
            userUuid: userId,
            matchId,
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });

    const data = await response.json();
    return data;
    /*
    return Promise.resolve({
        selectedPlayerId: playerId,
    })*/
}

export const loadTopIndexInfo = async (userUuid: string) => {
    const response = await fetch(`${TRANSPORT_BASE_URL}?userUuid=${userUuid}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const data = await response.json();
    return data;
    /*return Promise.resolve({
        status: TopIndexStatus.Results,
        players: [
            {
                id: '1',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            },
            {
                id: '2',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '3',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '4',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '5',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '6',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '7',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '8',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '9',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '10',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            }, {
                id: '11',
                name: "Йорди Рейна",
                photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
                indexScoreAverage: 332423,
                indexScoreLast: 332423,
                position: 'Полузащитник',
            },
        ],
        selectedPlayerId: '2',
        topScorePlayer: {
            id: '1',
            name: "Йорди Рейна",
            photoUrl: "https://fcrodina.com/club/00a448ee8448007156a8aae9480017db.PNG",
            indexScoreAverage: 332423,
            indexScoreLast: 332423,
            position: 'Полузащитник',
        },
    });*/
}