import { call, fork, put, takeLeading } from "redux-saga/effects";
import { loadConfig, loadConfigFailure, loadConfigSuccess } from "./reducer";
import { AppConfigResponse } from "./types";
import { getAppConfig } from "../../../api/transport/configTransport";

function* loadConfigWatcher() {
    yield takeLeading(loadConfig.type, onLoadConfigAsync);
}

function* onLoadConfigAsync({ payload }: any) {
    try {
        const responseData: AppConfigResponse = yield call(getAppConfig);

        yield put(loadConfigSuccess({
            appConfig: {...responseData},
        }));
    } catch (error: any) {
        yield put(loadConfigFailure(
            {
                error: /*error?.message ||*/ 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}


export function* configSaga() {
    yield fork(loadConfigWatcher);
}