import { Link } from "react-router-dom"

export const AskCoach = () => {
    return (
        <Link to="/ask-coach" className="p-4 relative w-full min-h-16 rounded-3xl text-white bg-sky-950 flex flex-row items-center justify-center gap-1 active:scale-95 transition-all">
            {/*<svg className="w-6 h-6 text-inherit" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z" />
            </svg>*/}
            <span className="font-bold uppercase text-xl text-center">
                Задай вопрос <br /> Зорану Зельковичу
            </span>
        </Link>
    )
}