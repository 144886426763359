import { useEffect, useState } from "react";
import { PlayerCard } from "./PlayerCard";
import { Button } from "./components/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectPlayer } from "../../store/slices/top-index/reducer";
import { useNavigate } from "react-router-dom";
import { selectAuth, selectTopIndex } from "../../store/selectors";
import { TopIndexStatus } from "../../store/slices/top-index/types";

export const TopIndexSelectionPage = () => {
    const [selectedId, setSelectedId] = useState<string>('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const topIndex = useAppSelector(selectTopIndex);
    const auth = useAppSelector(selectAuth);

    useEffect(() => {
        if (topIndex.status !== TopIndexStatus.Selection) {
            navigate('/top-index', { replace: true });
        }
    }, [navigate, topIndex.status]);

    return (
        <div className="bg-slate-900 text-white w-full min-h-full pt-2 pb-16">
            <div className="p-4 flex flex-col max-w-96 mx-auto gap-3">
                <h1 className="text-2xl font-bold text-center uppercase pb-4">Выбери игрока</h1>
                <div className="grid grid-cols-2 gap-2">
                    {topIndex?.players?.map(p => {
                        return <PlayerCard
                            key={p.id}
                            name={p.name}
                            photoUrl={p.photoUrl}
                            index={p.indexScoreAverage}
                            selected={selectedId === p.id}
                            position={p.position}
                            onSelect={() => {
                                if (p.id !== selectedId) {
                                    setSelectedId(p.id)
                                } else {
                                    setSelectedId('');
                                }
                            }}
                        />
                    })}
                </div>
                {selectedId && <div className="fixed bottom-32 left-0 right-0 text-center flex items-center justify-center p-1 px-6">
                    <Button onClick={() => {
                        dispatch(selectPlayer({
                            playerId: selectedId,
                            userUuid: auth.uuid,
                            matchId: topIndex.matchId,
                        }));
                        navigate("/top-index", {
                            replace: true,
                        })
                    }}>Подтвердить</Button>
                </div>}
            </div>
        </div>
    );
}